<template>
  <v-banner
    v-if="user !== null && (user.attributes['custom:CookiesConsent'] !== '1')"
    :single-line="false"
    icon="info"
    :elevation="4"
    color="info"
    icon-color="white"
  >
    <h1 class="my-3">{{ $t('gdpr.title') }}</h1>
    <p style="text-align:justify;" v-html="$t('gdpr.text')"></p>
    <template v-slot:actions>
      <v-btn
        color="success"
        @click="setConsent(true)"
      >
        {{ $t('misc.accept') }}
      </v-btn>
      <v-btn
        color="red"
        @click="setConsent(false)"
      >
        {{ $t('misc.deny') }}
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data: () => ({
    user: null,
  }),
  async beforeCreate() {
    this.user = await Auth.currentAuthenticatedUser();
  },
  methods: {
    async setConsent(value) {
      if (value) {
        console.log(await Auth.updateUserAttributes(this.user, {
          'custom:CookiesConsent': '1',
        }));
      } else {
        await Auth.updateUserAttributes(this.user, {
          'custom:CookiesConsent': '0',
        });
      }
      this.user = await Auth.currentAuthenticatedUser();
    },
  },
};
</script>

<style>
.v-banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
</style>
