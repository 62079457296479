import axios from 'axios';
import applicationSettings from '@/backend/static-data/applicationSettings';
import Cache from './Cache';

export default class Api {
  constructor(backend) {
    this.data = {
      applicationSettings,
    };

    this.headers = {
      'x-api-key': backend.apiKey,
    };
    this.apiName = '';
    if (process.env.VUE_APP_LAMBDA_API_NAME) {
      this.apiName = process.env.VUE_APP_LAMBDA_API_NAME;
    }

    this.api = axios.create({
      baseURL: backend.host,
      timeout: 2000,
      headers: {
        'x-api-key': backend.apiKey,
      },
    });
  }

  /**
   * POST request to backend
   *
   * @param {string} path object to request
   * @param {Record<string, unknown>} queryParams uri query string
   * @param {Record<string, unknown>} body the body of the request
   * @param {string} [responseType="json"] the response type of the resquest
   * (json, text, blob, document, ...)
   *
   * @return {Promise<any>}
   */
  async post(path, queryParams, body, responseType = 'json') {
    if (path.includes('applicationDevices') || path.includes('applicationSettings')) {
      if (process.env.NODE_ENV !== 'production') {
        const msg = '[API.Post] tryed to post to applicationDevices | applicationSettings path';
        console.log(msg, path, queryParams, body);
      }
      return {};
    }
    const params = {
      headers: this.headers,
      response: true,
      responseType,
      queryStringParameters: {
        ...queryParams,
      },
      body,
    };
    const uri = path.startsWith('/') ? path : `/${path}`;
    const cacheData = { path, queryParams, body };
    const requestData = { apiName: this.apiName, uri, params };
    return Cache.makeCachedRequest(cacheData, requestData, 'POST');
  }

  /**
   * GET query data from backend
   *
   * @param {string} path object to request
   * @param {Record<string, unknown>} queryParams uri query string
   * @param {string} [responseType="json"] the response type of the resquest
   * (json, text, blob, document, ...)
   *
   * @return {Promise<any>}
   */
  async get(path, queryParams, responseType = 'json') {
    const [object] = path.split('/');
    if (typeof this.data[object] !== 'undefined') {
      return { data: this.data[object], status: 200 };
    }
    const params = {
      headers: this.headers,
      response: true,
      responseType,
      queryStringParameters: {
        ...queryParams,
      },
    };
    const uri = path.startsWith('/') ? path : `/${path}`;
    // return API.get(this.apiName, uri, params);
    const cacheData = { path, queryParams, body: {} };
    const requestData = { apiName: this.apiName, uri, params };
    return Cache.makeCachedRequest(cacheData, requestData, 'GET');
  }

  /**
   * PUT data to backend
   *
   * @param {string} path object to request
   * @param {Record<string, unknown>} queryParams uri query string
   * @param {Record<string, unknown>} body the body of the request
   *
   * @return {Promise<any>}
   */
  async put(path, queryParams, body) {
    const params = {
      headers: this.headers,
      response: true,
      queryStringParameters: {
        ...queryParams,
      },
      body,
    };
    const uri = path.startsWith('/') ? path : `/${path}`;
    const requestData = { apiName: this.apiName, uri, params };
    return Cache.makeRequestAndInvalidateCache(path, requestData, 'PUT');
  }

  /**
   * DELETE data from backend
   *
   * @param {string} path object to request
   * @param {Record<string, unknown>} queryParams uri query string
   *
   * @return {Promise<any>}
   */
  async delete(path, queryParams) {
    const params = {
      headers: this.headers,
      response: true,
      queryStringParameters: {
        ...queryParams,
      },
    };
    const uri = path.startsWith('/') ? path : `/${path}`;
    const requestData = { apiName: this.apiName, uri, params };
    return Cache.makeRequestAndInvalidateCache(path, requestData, 'DELETE');
  }
}
