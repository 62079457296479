import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '../assets/ffly4u.css';
import Fields from '../components/crud/fields/Fields.vue';

Vue.use(Vuetify);
Vue.component('Fields', Fields);

// Export a function for create Vuetify instance to allow extend the options after settings fetch
export default function createVuetify(options, i18n) {
  return new Vuetify({
    lang: {
      t: (key, ...params) => i18n.t(key, params),
    },
    icons: {
      iconfont: 'mdi',
    },
    ...options,
  });
}
