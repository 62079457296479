<template>
  <v-layout align-start justify-center>
    <v-flex
      v-if="filters"
      xs12
    >
      <component
        v-for="(field, index) in fields"
        :key="`${groupId} ${field.fid}-${index}`.trim()"
        v-bind:is="field.component"
        :groupId="groupId"
        v-bind="field"
        :componentData="field"
        :filtersProp="filters"
        :readonly="readonly || field.readonly"
        :extLoading="extLoading"
        v-on:change="onChange(field, $event)"
      >
      </component>
    </v-flex>
    <v-flex
      v-else
      xs12
    >
      <component
        v-for="(field, index) in fields"
        :key="`${groupId} ${field.fid}-${index}`.trim()"
        v-bind:is="field.component"
        :groupId="groupId"
        v-bind="field"
        :componentData="field"
        :readonly="readonly || field.readonly"
        :extLoading="extLoading"
        v-on:change="onChange(field, $event)"
      >
      </component>
    </v-flex>
  </v-layout>
</template>

<script>
import _ from '@/misc/lodash';
import fields from '.';

export default {
  name: 'Fields',
  components: fields,
  props: {
    groupId: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    extLoading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: [Object, Array],
      default: null,
    },
  },
  methods: {
    onChange(field, value) {
      const updatedFields = [...this.fields];
      const index = updatedFields.findIndex((cF) => cF.fid === field.fid);
      updatedFields[index].value = value;
      updatedFields[index].edited = !_.isEqual(updatedFields[index].initial, value);

      this.$emit('change', updatedFields);
    },
  },
};
</script>
