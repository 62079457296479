import Vue from 'vue';
import VueMeta from 'vue-meta';
import LogRocket from 'logrocket';
import VueWorker from 'vue-worker';
import Amplify, { Hub, Cache, Storage } from 'aws-amplify';
import awsconfig from '@/backend/static-data/aws-config';
import { createBackend, Lambda } from '@/backend';
import createI18n from '@/plugins/i18n';
import createVuetify from '@/plugins/vuetify';
import '@/plugins/registerServiceWorker';
import App from '@/App.vue';
import createRouter from '@/router';
import store from '@/store';
import _ from '@/misc/lodash';

// Requests Cache duration in minutes
const cacheDuration = parseInt(process.env.VUE_APP_CACHE_DURATION || 5, 10);

const appVersion = process.env.VUE_APP_VERSION;
const currentAppVersion = localStorage.getItem('app_version');

if (currentAppVersion && currentAppVersion !== appVersion) {
  const data = {
    id: null,
    title: 'Nouvelle version',
    description: 'Une nouvelle version de l\'application est disponible, vous allez être redirigé vers la page de connexion après la mise à jour.',
    color: 'default',
    timeout: 10000,
  };
  store.commit('snackbars/ADD_MESSAGE', data);
  localStorage.setItem('app_version', appVersion);
  const user = store.state.auth.currentUserffly4u;
  setTimeout(() => {
    localStorage.clear();
    store.commit('auth/SET_CURRENT_USER_FFLY4U', user);
    window.location.reload();
  }, 10000);
} else if (currentAppVersion === null) {
  localStorage.setItem('app_version', appVersion);
}

// let LogRocketEnabled = false;
const logOptions = {
  /**
   * If you would like to merge the iFrames into a single video
   * @see https://docs.logrocket.com/reference#mergeiframes
   */
  mergeIframes: true,
};
// Log on correct environment for the hostname (localhost is excluded)
if (typeof window.logRocketEnv !== 'undefined') {
  LogRocket.init(window.logRocketEnv, logOptions);
}

// Configure Amplify Object with application credentials
Amplify.configure(awsconfig);
Storage.configure({ level: 'protected' });

const config = {
  // itemMaxSize: 3000, // 3000 bytes
  // storage: window.sessionStorage // switch to sessionStorage
  // ...
  defaultTTL: 1000 * 60 * cacheDuration, // Time for the cache to live in milliseconds
};
Cache.configure(config);

(async () => {
  Vue.config.productionTip = false;

  const backend = createBackend(Lambda, { store });
  await backend.connect();

  // AWS Cognito Auth Plugin sends important events through Amplify Hub.
  // So we define here a listener to handle those events
  const listener = (info) => {
    switch (info.payload.event) {
      case 'signIn':
        if (process.env.NODE_ENV !== 'production') {
          console.info('user signed in');
          // const { attributes, username } = info.payload.data;
          // console.log(username, attributes);
        }
        break;
      case 'signUp':
        if (process.env.NODE_ENV !== 'production') {
          console.info('user signed up');
        }
        break;
      case 'signOut':
        if (process.env.NODE_ENV !== 'production') {
          console.info('user signed out');
          // console.log(info.payload);
        }
        break;
      case 'forgotPassword':
        if (process.env.NODE_ENV !== 'production') {
          console.info(info.payload.message);
          console.log(info.payload);
        }
        break;
      case 'signIn_failure':
        if (process.env.NODE_ENV !== 'production') {
          console.error('user sign in failed');
        }
        break;
      case 'tokenRefresh':
        if (process.env.NODE_ENV !== 'production') {
          console.info('token refresh succeeded');
        }
        break;
      case 'tokenRefresh_failure':
        console.error('token refresh failed');
        store.dispatch('auth/LOG_OUT');
        break;
      case 'configured':
        if (process.env.NODE_ENV !== 'production') {
          console.info('the Auth module is configured');
        }
        break;
      default:
        if (process.env.NODE_ENV !== 'production') {
          console.error('AWS HUB Listener default case [main.js]. payload = ');
          console.log(info.payload);
        }
        break;
    }
  };

  Hub.listen('auth', listener);

  if (store.state.auth.currentUser) {
    await store.dispatch('settings/FETCH_APPLICATIONS');
    if (localStorage.currentApplication
        && store.state.settings.apps
        && store.state.settings.applications.length === 0) {
      let customer;
      let id;
      if (store.state.settings.apps.length > 1) {
        ({ customer, id } = store.state.settings.apps
          .find((app) => app.id === localStorage.currentApplication));
        await store.dispatch('settings/FETCH_SETTINGS', { customer, id });
      } else if (store.state.settings.apps.length === 1) {
        [{ customer, id }] = store.state.settings.apps;
        await store.dispatch('settings/FETCH_SETTINGS', { customer, id });
      } else if (process.env.NODE_ENV !== 'production') {
        console.error('Error with applications number', store.state.settings.apps);
      }
    }
  }

  const i18n = createI18n(localStorage.locale);
  // Create vuetify instance with the application theme
  const vuetify = createVuetify(
    {
      theme: {
        themes: {
          light: store.state.settings.theme,
        },
      },
    },
    i18n,
  );

  const router = createRouter(store);

  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
  });
  Vue.use(VueWorker);

  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App),
    computed: {
      theme() {
        return this.$store.state.settings.theme;
      },
      locales() {
        return this.$store.state.settings.locales;
      },
    },
    watch: {
      theme() {
        Object.assign(this.$vuetify.theme.themes.light, this.theme);
      },
      locales() {
        if (Array.isArray(this.locales)) {
          this.locales.forEach((locale) => {
            this.$i18n.mergeLocaleMessage(locale.locale, locale.data);
          });
        }
      },
      '$i18n.locale': function onLocaleChange() {
        localStorage.locale = this.$i18n.locale;
        this.$vuetify.lang.current = this.$i18n.locale;
        const params = {
          locale: {
            name: 'locale',
            type: 'keyword',
            keyword: this.$i18n.locale,
          },
        };

        this.$store.dispatch('auth/UPDATE_SELF', params);

        if (this.$store.state.auth.currentUser) {
          this.$store.dispatch('auth/UPDATE_SELF', params);
        }
      },
    },
    mounted() {
      if (!_.isEmpty(this.locales)) {
        Object.entries(this.locales).forEach(([locale, data]) => {
          this.$i18n.mergeLocaleMessage(locale, data);
        });
      }

      const { locale } = localStorage;

      // TODO update get current user informations
      // const { currentUserffly4u } = this.$store.state.auth;
      // if (currentUserffly4u) {
      //   locale = currentUserffly4u._source.locale || locale;
      // }

      /*
      if (LogRocketEnabled && currentUserffly4u !== null) {
        const profiles = currentUserffly4u._source.profiles.map(({ profile }) => (profile));
        const application = this.$store.getters['settings/currentApplication'];

        LogRocket.identify(currentUserffly4u.id, {
          name: currentUserffly4u._source.name,
          email: currentUserffly4u._source.email,
          locale,
          application: application.name,
          profiles: profiles.join(', '),
        });
      }
      */

      if (locale) {
        // localStorage.locale = locale;
        this.$i18n.locale = locale;
        this.$vuetify.lang.current = this.$i18n.locale;
      }
    },
  }).$mount('#app');
})();
