import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import formatDate from 'date-fns/format';

const dateLocales = {
  en,
  fr,
};

export default async function format(_value, options) {
  const value = Number(_value);
  let formattedValue = value;

  if (value) {
    formattedValue = formatDate(new Date(value), options.template || 'P HH:mm:ss OOOO', {
      locale: dateLocales[localStorage.locale],
    });
  } else {
    formattedValue = '';
  }

  return formattedValue;
}
