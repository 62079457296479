/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/**
 * Interface for Backend classes
 *
 * @interface
 */
export default class BackendInterface {
  connection = null

  /**
   * Search objects
   *
   * @param {string|object} controller controller to request
   * @param {object} body body of request
   *
   * @return {Promise} response of request
   */
  async search(controller, body) {
    throw new Error('You must implement this function');
  }

  /**
   * Create new object
   *
   * @param {string|object} controller controller to request
   * @param {object} data data of object
   *
   * @return {Promise} response of request
   */
  async create(controller, data) {
    throw new Error('You must implement this function');
  }

  /**
   * Read single object
   *
   * @param {string|object} controller controller to request
   * @param {string} objectId object id to read
   *
   * @return {Promise} response of request
   */
  async read(controller, objectId) {
    throw new Error('You must implement this function');
  }

  /**
   * Update an object
   *
   * @param {string|object} controller controller to request
   * @param {string} objectId object id to update
   * @param {object} data new data of object
   *
   * @return {Promise} response of request
   */
  async update(controller, objectId, data) {
    throw new Error('You must implement this function');
  }

  /**
   * Delete an object
   *
   * @param {string|object} controller controller to request
   * @param {string} objectId object id to delete
   *
   * @return {Promise} response of request
   */
  async delete(controller, objectId) {
    throw new Error('You must implement this function');
  }
}
