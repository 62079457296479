const fields = {};

const fieldFiles = require.context('.', true, /[A-Za-z0-9-_,\s]+Field\.vue$/i, 'lazy');
fieldFiles.keys().forEach((key) => {
  let fieldName = key.substring(2);
  fieldName = fieldName.substring(0, fieldName.length - 4);
  fields[fieldName] = () => fieldFiles(key);
});

export default fields;
