import Backend from '../backend';

const state = {
  data: {
    /* applications: {
      body: [],
      total: 0,
    }, */
  },
};

const actions = {
  SEARCH: async ({ commit, state }, {
    object,
    alias,
    body,
    all = false,
    store = true,
  }) => {
    let result = false;
    try {
      const data = await Backend().search(object, body, all);

      if (store) {
        commit('SET_DATA', {
          object: alias || object,
          data,
        });
        result = state.data[alias || object];
      } else {
        result = data;
      }
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return result;
  },
  FETCH: async ({ commit, state }, {
    object,
    alias,
    objectId,
    params,
    store = true,
  }) => {
    let result = false;
    try {
      const data = await Backend().read(object, objectId, params);
      if (store) {
        commit('SET_DATA', {
          object: alias || object,
          data,
        });
        result = state.data[alias || object];
      } else {
        result = data;
      }
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return result;
  },
  EXPORT: async ({ commit }, {
    object,
    body,
    all = false,
  }) => {
    let result = false;
    try {
      const data = await Backend().export(object, body, all);
      result = data;
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return result;
  },
  CREATE: async ({ commit }, {
    object,
    alias,
    body,
    params,
    store = true,
  }) => {
    try {
      const data = await Backend().create(object, body, params);
      if (store && data !== false) {
        commit('UPDATE_DATA', {
          object,
          alias,
          data,
        });
      }
      return data;
    } catch (error) {
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return false;
  },
  READ: async ({ commit }, {
    object,
    alias,
    objectId,
    params,
    store = true,
  }) => {
    try {
      const data = await Backend().read(object, objectId, params);
      if (store) {
        commit('UPDATE_DATA', {
          object,
          alias,
          data,
        });
      }
      return data;
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return false;
  },
  READ_ITEM: async ({ commit }, {
    object,
    objectId,
    params,
    store = true,
  }) => {
    try {
      const data = await Backend().read(object, objectId, params);
      if (store) {
        commit('UPDATE_ITEM_DATA', {
          object,
          data,
          objectId,
        });
      }
      return data;
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return false;
  },
  READ_STATUS: async ({ dispatch }, { body }) => {
    await Backend().query({
      controller: 'application-connect/analyticsController',
      action: 'readstatus',
      body,
    });
    dispatch('crud/READ', {
      object: body.object,
      alias: body.alias || body.object,
      objectId: body.objectId,
      plugin: body.plugin,
      store: true,
    }, { root: true });
  },
  UPDATE: async ({ commit }, {
    object,
    alias,
    objectId,
    body,
    params,
    store = true,
  }) => {
    try {
      const data = await Backend().update(object, objectId, body, params);
      if (store && data !== false) {
        commit('UPDATE_DATA', {
          object,
          alias,
          data,
        });
      }
      return data;
    } catch (error) {
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return false;
  },
  DELETE: async ({ commit }, {
    object,
    alias,
    objectId,
    params,
    store = true,
  }) => {
    try {
      const data = Backend().delete(object, objectId, params);
      if (store) {
        commit('DELETE_DATA', {
          object,
          alias,
          objectId,
        });
      }
      return data;
    } catch (error) {
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return false;
  },
};

const mutations = {
  SET_DATA: (state, {
    object,
    alias,
    data,
  }) => {
    const key = alias || object;

    if (!state.data[key]) {
      state.data[key] = {
        body: [],
      };
    }

    state.data[key].body.forEach((hit) => {
      Object.assign(hit, { current: false, order: -1 });
    });
    data.body.forEach((hit, order) => {
      Object.assign(hit, { current: true, order });

      const index = state.data[key].body.findIndex((h) => {
        if (h.id) {
          return h.id === hit.id;
        }
        return h.name === hit.name;
      });

      if (index > -1) {
        state.data[key].body.splice(index, 1, hit);
      } else {
        state.data[key].body.push(hit);
      }
    });

    state.data[key].currentResult = data.currentResult;
    state.data[key].totalResult = data.totalResult;
    if (typeof data.max !== 'undefined' && data.max !== null) {
      state.data[key].max = data.max;
    }
    state.data = { ...state.data };
  },
  UPDATE_DATA: (state, {
    object,
    alias,
    data,
  }) => {
    const key = alias || object;
    if (!state.data[key]) {
      state.data[key] = {
        body: [],
      };
    }

    const index = state.data[key].body.findIndex((doc) => (doc.id === data.id));

    if (index > -1) {
      Object.assign(data, {
        current: state.data[key].body[index].current,
        order: state.data[key].body[index].order,
      });
      state.data[key].body.splice(index, 1, data);
    } else {
      state.data[key].body.push(data);
    }

    state.data = { ...state.data };
  },
  UPDATE_ITEM_DATA: (state, {
    object,
    data,
    objectId,
  }) => {
    const key = object;
    if (!state.data[objectId]) {
      state.data[objectId] = { [key]: data };
    } else {
      state.data[objectId][key] = data;
    }

    state.data = { ...state.data };
  },
  DELETE_DATA: (state, {
    object,
    alias,
    objectId,
  }) => {
    const key = alias || object;
    if (!state.data[key]) {
      state.data[key] = {
        body: [],
      };
    }

    const index = state.data[key].body.findIndex((doc) => doc._id === objectId);

    if (index > -1) {
      state.data[key].body.splice(index, 1);
      state.data[key].total -= 1;
    }
    state.data = { ...state.data };
  },
  CLEAR_CACHE: (state, objects) => {
    let toReset = objects;

    // If lists of objects are not defined clear all data
    if (typeof objects === 'undefined') {
      toReset = Object.keys(state.data);
    }

    for (let i = 0; i < toReset.length; i += 1) {
      const object = toReset[i];
      if (state.data[object]) {
        delete state.data[object];
      }
    }
  },
};

const getters = {
  GET_DATA_BY_ID: (state) => (object, id) => (
    state.data[object] && state.data[object].body.find((doc) => doc.id === id)),
  // eslint-disable-next-line arrow-body-style
  GET_ITEM_DATA: (state) => (itemId) => (state.data[itemId]),
  GET_DATA: (state) => (state.data),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
