import Kuzzle from './Kuzzle';
import Lambda from './Lambda';
import BackendInterface from './BackendInterface';

let instance = null;

/**
 *
 * @param {BackendInterface} BackendClass A class that extends BackendInterface
 * @param {*} [options] options to instantiate BackendClass
 *
 * @return {Lambda|BackendInterface}
 */
function createBackend(BackendClass, options = {}) {
  if (instance === null) {
    if (BackendClass.prototype instanceof BackendInterface) {
      instance = new BackendClass(options);
    } else {
      throw new Error('It\'s not an valid BackendClass');
    }
  }
  return instance;
}

/**
 * Get Backend instance
 *
 * @return {Lambda|BackendInterface}
 */
function getInstance() {
  if (instance instanceof BackendInterface) {
    return instance;
  }
  throw new Error('Backend is not instantiate');
}

export {
  createBackend,
  getInstance,
  Lambda,
  Kuzzle,
  getInstance as default,
};
