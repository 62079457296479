<template>
  <v-list>
    <div>
      <v-list-group
        :key="group.name"
        color="white"
        no-action
        :value="group.expand"
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>{{ group.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(`pages.${group.name}.title`.toLowerCase()) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(page, index) in group.subPages"
          :key="index"
          class="pl-8"
          link
          :to="page.path"
        >
          <v-list-item-icon  class="mr-4">
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="$t(`pages.${page.name}.title`.toLowerCase())"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </div>
  </v-list>
</template>

<script>
export default {
  name: 'menu-list-group',
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
