import multiguard from 'vue-router-multiguard';

export default (store) => {
  delete localStorage.toLogin;
  const guards = [
    {
      name: 'authentication',
      // authenticationGuard is used to block unauthorized routes when not connected
      guard: async (to, from, next) => {
        try {
          if (await store.dispatch('auth/CHECK_TOKEN')) {
            if (localStorage.toLogin === 'true') {
              delete localStorage.toLogin;
              // This triggers an uncaught error that doesn't affect navigation
              next(localStorage.nextPath);
            } else {
              delete localStorage.nextPath;
              next();
            }
          } else {
            localStorage.toLogin = true;
            localStorage.nextPath = to.fullPath;
            store.dispatch('auth/CLEAR_ALL_DATA');
            next('/login');
          }
        } catch (error) {
          store.dispatch('auth/CLEAR_ALL_DATA');
          next('/login');
        }
      },
    },
    {
      name: 'connect',
      // If the user is connected, the login and register pages are not accessible
      guard: async (to, from, next) => {
        try {
          if (await store.dispatch('auth/CHECK_TOKEN')) {
            next('/');
          } else {
            next();
          }
        } catch (error) {
          next();
        }
      },
    },
  ];

  const getGuards = (routeGuards) => multiguard(
    guards
      .filter((guard) => routeGuards.indexOf(guard.name) > -1)
      .map((guard) => guard.guard),
  );
  return getGuards;
};
