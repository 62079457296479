import { Auth } from 'aws-amplify';
import BaseController from './BaseController';

/**
 * Dummy AuthController
 */
export default class AuthController extends BaseController {
  type = 'cognito'

  /**
   * User id
   *
   * @type {string}
   */
  uid = null

  constructor(backend) {
    super(backend);

    if (backend.constructor.name === 'Kuzzle') {
      this.type = 'kuzzle';
    }
  }

  /**
   * Send login request to AWS cognito
   *
   * @param {{ username: string, password: string }} credentials
   *
   * @returns {Promise<{loggedIn: boolean, redirect: Object}>} Object
   */
  // eslint-disable-next-line class-methods-use-this
  async login(credentials) {
    let loggedIn = true;
    let redirect = null;
    try {
      const user = await Auth.signIn(credentials.username, credentials.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // the array of required attributes, e.g ['email', 'phone_number']
        await this.backend.store.commit('auth/SET_COGNITO_USER', user);
        loggedIn = false;
        redirect = {
          name: 'new-password',
          query: { username: credentials.username },
        };
      } else if (process.env.NODE_ENV !== 'production') {
        console.log(user);
      }
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('error signing in', error);
      }
      loggedIn = false;
      redirect = null;
      throw error;
    }
    return { loggedIn, redirect };
  }

  /**
   * Send sign up request to AWS cognito
   *
   * @param { string } email
   * @param { string } username
   * @param { string } password
   *
   * @returns {Promise<{ Object}>} Promise
   */
  // eslint-disable-next-line class-methods-use-this
  async register(email, username, password) {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email, // optional
        },
      });
      if (process.env.NODE_ENV !== 'production') {
        console.log(user);
      }
      return user;
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('error registering user', error);
      }
      throw error;
    }
  }

  /**
  * Send new password request to AWS cognito
  *
  * @param {{ newPassword: string, cognitoUser: Object }} credentials
  *
  * @returns {Promise<{changed: boolean, redirect: Object}>} Object
  */
  // eslint-disable-next-line class-methods-use-this
  async newPassword(newPassword, cognitoUser) {
    let changed = false;
    let redirect = null;
    try {
      await Auth.completeNewPassword(
        cognitoUser, // the Cognito User Object
        newPassword, // the new password
      );
      changed = true;
      redirect = '/';
      return {
        changed,
        redirect,
      };
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.info('error setting new password', error);
      }
      throw error;
    }
  }

  /**
  * Send forgot password request to AWS cognito
  *
  * @param {string} username
  *
  * @returns {Promise<{Object}>} Object
  */
  // eslint-disable-next-line class-methods-use-this
  async forgotPassword(username) {
    try {
      await Auth.forgotPassword(username);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.info('error sending reset password code', error);
      }
      throw error;
    }
  }

  /**
  * Send forgot password submit request to AWS cognito
  *
  * @param {string} username
  * @param {Number} code
  * @param {string} newPassword
  *
  * @returns {Promise<{Object}>} Object
  */
  // eslint-disable-next-line class-methods-use-this
  async forgotPasswordSubmit(username, code, newPassword) {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.info('error changing password', error);
      }
      throw error;
    }
  }

  /**
   * Send login request to AWS cognito
   *
   * @param
   *
   * @returns {Promise<Object>} Object
   */
  // eslint-disable-next-line class-methods-use-this
  async logout() {
    try {
      await Auth.signOut();
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('error signing out: ', error);
      }
      throw error;
    }
  }

  async getMyCredentials() {
    if (this.type === 'kuzzle') {
      const credentials = await this.connection.auth.getMyCredentials('local');
      this.uid = credentials.kuid;
      return credentials;
    }
    this.uid = 'test-user';
    const { attributes, username } = await Auth.currentAuthenticatedUser();
    return {
      username,
      attributes,
    };
  }

  /**
   * Check if token is valid
   */
  // eslint-disable-next-line class-methods-use-this
  async checkToken() {
    let valid = false;
    try {
      const { attributes, username } = await Auth.currentAuthenticatedUser();
      this.backend.store.commit('auth/SET_CURRENT_USER', { username, attributes });
      valid = true;
    } catch (err) {
      valid = false;
      if (process.env.NODE_ENV !== 'production') {
        console.log('[AuthController.checkToken] no cognito user: ', err);
      }
    }
    return valid;
  }
}
