import _ from '../misc/lodash';
import QueryBuilder, { QueryBuilderClass } from '../misc/QueryBuilder';

/**
 * Fallback for legacy QueryBuilder (Simple object)
 * Check if query.q it's already an QueryBuilder instance or create then if necessary
 * QueryBuilder instance it's request to convert SimpleQuery in FakeQuery
 *
 * @example
 * Input
 * ```
 *  {
 *    n: 10,
 *    p: 1,
 *    q: {...},
 *  }
 * ```
 *
 *  Output
 * ```
 *  {
 *    n: 10,
 *    p: 1,
 *    q: QueryBuilder({...}),
 *  }
 * ```
 *
 * @param {object} body body of query
 *
 * @return {object} Object computed with body.q in QueryBuilder instance
 */
function getFakeQuery(body) {
  if (typeof body.q !== 'undefined') {
    if (body.q instanceof QueryBuilderClass) {
      body.q = body.q.getQuery('fake');
    } else {
      body.q = QueryBuilder(body.q, null, 'fake');
    }
  }
  return body;
}

/**
 * Check with _.validate() if value is equal to "filter.value"
 * the "filter.not" reverse the result
 *
 * @param {object} filter object of filter condition
 * @example filter
 *```
 *{
 *  value: "area",
 *  not: false;
 *}
 *```
 *
 * @param {*} value value to check
 *
 * @return {boolean} result of validate
 */
function checkOneFilter(filter, value) {
  if (filter.not === true) {
    return !_.validate('deepEqual', value, filter.value);
  }
  return _.validate('deepEqual', value, filter.value);
}

/**
 * Check if every filters return true
 *
 * @see checkOneFilter for format of one filter
 *
 * @param {object[]} filters Array of filters to check
 * @param {object} obj Object of hit to check
 *
 * @return {boolean} Result for all filter
 */
function checkFilters(filters, obj) {
  return filters.every((filter) => {
    if (filter.nested) {
      return _.get(obj, filter.key, []).find((subValue) => (
        checkFilters(filter.nested, subValue)
      ));
    }
    // Get value to filter, caution for _id it is directly in hit not in hit._source
    const value = obj[filter.key];
    if (Array.isArray(filter.value)) {
      return filter.value.some((sub) => checkOneFilter({ value: sub }, value));
    }
    return checkOneFilter(filter, value);
  });
}

/**
 * Request fake data in current state
 *
 * @example filter
 * Multiple result (body it's an Object)
 *```
 *{
 *  hits: [...hit],
 *  total: Number,
 *}
 *```
 *
 * Single result (body it's an String)
 *```
 *{
 *  result: {hit},
 *}
 *```
 *
 * @param {object} state state of current vuex module
 * @param {string} object object to request (corresponding to index in Kuzzle)
 * @param {object|string} body Body of request is an Object to search or a String to get one object
 * @param {boolean} all It's an Boolean to define if you want all result or paginated result
 *
 * @return {object} Result of query
 */
function queryFakeData(state, object, body, all) {
  let hits = [];
  let total = 0;
  if (typeof body === 'object') {
    const query = getFakeQuery({ ...body });

    if (typeof query.q !== 'undefined') {
      if (Array.isArray(query.q)) {
        hits = state.fakeData[object].filter(
          ({ _id, _source }) => checkFilters(query.q, { _id, ..._source }),
        );
      }
    }
    total = hits.length;

    if (all !== true && query.n > -1) {
      // Quick fix for consolidation because paginate with 2 object
      if (object === 'consolidation') {
        query.n *= 2;
      }
      hits = hits.slice(query.n * (query.p - 1), query.n * query.p);
    }
  } else if (typeof body === 'string') {
    const result = state.fakeData[object].find(({ _id }) => _id === body);
    return { result };
  }
  return {
    result: { hits, total },
  };
}

const state = {
  fakeData: {
    /* applications: [
      ...hits
    ], */
  },
  data: {
    /* applications: {
      hits: [],
      total: 0,
    }, */
  },
};

const actions = {
  SEARCH: async ({ commit, state }, {
    // plugin = 'environment-manager', // unused
    object,
    alias,
    body,
    all = false,
    store = true,
  }) => {
    let result = false;
    try {
      const data = queryFakeData(state, object, { ...body }, all);
      if (store) {
        commit('SET_DATA', {
          object: alias || object,
          data,
        });
        result = state.data[alias || object];
      } else {
        ({ result } = data);
      }
    } catch (error) {
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: 5000,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return result;
  },
  CREATE: async ({ commit }) => {
    let result = false;
    try {
      const data = {
        id: null,
        title: 'Info',
        description: 'You cannot create in demo mode',
        color: 'info',
        timeout: 5000,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
      console.error('Need implement CREATE');
      result = false;
    } catch (error) {
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: 5000,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return result;
  },
  READ: async ({ commit }, {
    // plugin = 'environment-manager', // unused
    object,
    alias,
    objectId,
    store = true,
  }) => {
    let result = false;
    try {
      const data = queryFakeData(state, object, objectId);
      if (store) {
        commit('UPDATE_DATA', {
          object,
          alias,
          data: data.result,
        });
      }
      ({ result } = data);
    } catch (error) {
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: 5000,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return result;
  },
  READ_STATUS: async (/* { dispatch }, { body } */) => {
    console.error('Need implement READ_STATUS');
  },
  UPDATE: async ({ commit }) => {
    /**
     * Second parameter unused
      {
        plugin = 'environment-manager',
        object,
        alias,
        objectId,
        body,
        store = true,
      }
     */
    let result = false;
    try {
      console.error('Need implement UPDATE');
      result = [];
    } catch (error) {
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: 5000,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return result;
  },
  DELETE: async ({ commit }) => {
    /**
     * Second parameter unused
      {
        plugin = 'environment-manager',
        object,
        alias,
        objectId,
        store = true,
      }
     */
    let result = false;
    try {
      console.error('Need implement DELETE');
      result = [];
    } catch (error) {
      const data = {
        id: null,
        title: 'Error',
        description: error.message || 'An error occurred',
        color: 'error',
        timeout: 5000,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
    return result;
  },
};

const mutations = {
  INIT_DATA(state, data) {
    Object.entries(data).forEach(([index, entry]) => {
      if (typeof state.fakeData[index] === 'undefined') {
        let dataEntry = entry;
        if (typeof dataEntry === 'string') {
          dataEntry = JSON.parse(dataEntry);
        }
        state.fakeData[index] = dataEntry;
      }
    });
  },
  SET_DATA: (state, {
    object,
    alias,
    data,
  }) => {
    const key = alias || object;
    if (!state.data[key]) {
      state.data[key] = {
        hits: [],
      };
    }

    state.data[key].hits.forEach((hit) => {
      Object.assign(hit, { current: false, order: -1 });
    });

    data.result.hits.forEach((hit, order) => {
      Object.assign(hit, { current: true, order });

      const index = state.data[key].hits.findIndex((h) => (
        h._id === hit._id && h._index === hit._index));

      if (index > -1) {
        state.data[key].hits.splice(index, 1, hit);
      } else {
        state.data[key].hits.push(hit);
      }
    });
    state.data[key].total = data.result.total;
    state.data = { ...state.data };
  },
  UPDATE_DATA: (state, {
    object,
    alias,
    data,
  }) => {
    const key = alias || object;
    if (!state.data[key]) {
      state.data[key] = {
        hits: [],
      };
    }

    const index = state.data[key].hits.findIndex((doc) => (
      doc._id === data._id && doc._index === data._index));

    if (index > -1) {
      Object.assign(data, {
        current: state.data[key].hits[index].current,
        order: state.data[key].hits[index].order,
      });
      state.data[key].hits.splice(index, 1, data);
    } else {
      state.data[key].hits.push(data);
      state.data[key].total += 1;
    }

    state.data = { ...state.data };
  },
  DELETE_DATA: (state, {
    object,
    alias,
    objectId,
  }) => {
    const key = alias || object;
    if (!state.data[key]) {
      state.data[key] = {
        hits: [],
      };
    }

    const index = state.data[key].hits.findIndex((doc) => doc._id === objectId);

    if (index > -1) {
      state.data[key].hits.splice(index, 1);
      state.data[key].total -= 1;
    }
    state.data = { ...state.data };
  },
  CLEAR_CACHE: (state, objects) => {
    let toReset = objects;

    // If lists of objects are not defined clear all data
    if (typeof objects === 'undefined') {
      toReset = Object.keys(state.data);
    }

    for (let i = 0; i < toReset.length; i += 1) {
      const object = toReset[i];
      if (state.data[object]) {
        delete state.data[object];
      }
    }
  },
};

const getters = {
  GET_DATA_BY_ID: (state) => (object, id) => (
    state.data[object]
    && state.data[object].hits
    && state.data[object].hits.find((doc) => doc._id === id)
  ),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
