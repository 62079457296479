import guid from '../misc/guid';

const state = {
  messages: [],
};

const actions = {};

const mutations = {
  ADD_MESSAGE: (state, data) => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(data);
    }
    // data has the following format
    // {
    //   title: 'Logout',
    //   loading: wether or not display a loading animation,
    //   icon: an icon,
    //   description: 'Logout error',
    //   color: 'error',
    //   timeout: 0,
    // },

    data.id = guid(8);
    state.messages.push(data);
    return data.id;
  },
  REMOVE_MESSAGE: (state, id) => {
    const findIndex = (message) => (message.id === id);
    const index = state.messages.findIndex(findIndex);

    if (index > -1) {
      state.messages.splice(index, 1);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
