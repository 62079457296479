import _ from './lodash';

class QueryBuilder {
  template = '';

  query = '';

  values = [];

  /**
   * Create QueryBuilder
   *
   * @param {object} template Template of query
   * @param {object} values Values to compute in template
   */
  constructor(template, values) {
    const templateQuery = JSON.stringify(template);
    this.template = templateQuery;
    this.query = templateQuery;

    if (values !== null && typeof values === 'object') {
      this.values = new Map(Object.entries(values));
    }
    this._buildQuery();
  }

  /**
   * Build the query with values
   *
   * If this.query is an string replace all template values (ex: %value%) by real value
   * and parse template in Object
   *
   * @private
   */
  _buildQuery() {
    if (typeof this.query !== 'object') {
      let { query } = this;
      // For each value replace %key% by value
      this.values.forEach((v, k) => {
        let value = v !== null ? v : ''; // If value is null get empty string to prevent error
        let key = `%${k}%`;
        // For non string replace "%key%" and JSON.stringify the value
        if (typeof value !== 'string') {
          key = `"${key}"`;
          value = JSON.stringify(value);
        }
        query = query.replace(new RegExp(key, 'g'), value);
      });
      this.query = JSON.parse(query);
    }
  }

  /**
   * Build fake query
   * It's an simplified query with only term/terms filters
   *
   * @private
   *
   * @example
   * Input
   *```
   *{
   *  query: {
   *    must: [
   *      {
   *        term: {
   *          keyToFilter: 'valueOfFilter',
   *        },
   *        ...
   *      },
   *      {
   *        nested: {
   *          path: 'keyToFilter',
   *          query: {
   *            must_not: [
   *              term: {
   *                keyToFilter: 'valueOfFilter',
   *              },
   *              ...
   *            ],
   *          },
   *        },
   *      },
   *    ],
   *  },
   *},
   *```
   *
   * Output
   *```
   *[
   *  {
   *    key: 'keyToFilter',
   *    value: "area",
   *    not: false,
   *  }
   *  ...
   *]
   *```
   *
   * ! Params are only used by _buildFakeFilters calls for nested queries
   * @param {object} [query] Query to compute in Fake query
   * @param {boolean} [not] Boolean to reverse the filter
   *
   * @return {object[]} Result is an array of filters
   */
  _buildFakeQuery(query, not = false) {
    const fakeQuery = [];
    if (typeof query.bool !== 'undefined') {
      if (Array.isArray(query.bool.must)) {
        fakeQuery.push(...this._buildFakeFilters(query.bool.must, not));
      }
      if (Array.isArray(query.bool.must_not)) {
        fakeQuery.push(...this._buildFakeFilters(query.bool.must_not, true));
      }
    }
    return fakeQuery;
  }

  /**
   * Convert the Filters in FakeFilters
   * For the moment only support term/terms filters
   *
   * @example
   * Input
   *```
   *[
   *  {
   *    term: {
   *      keyToFilter: 'valueOfFilter',
   *    },
   *    ...
   *  },
   *  {
   *    nested: {
   *      path: 'keyToFilter',
   *      query: {
   *        must_not: [
   *          term: {
   *            keyToFilter: 'valueOfFilter',
   *          },
   *          ...
   *        ],
   *      },
   *    },
   *  },
   *]
   *```
   *
   * Output
   *```
   *[
   *  {
   *    key: 'keyToFilter',
   *    value: 'valueOfFilter',
   *    not: false,
   *  },
   *  {
   *    key: 'keyToFilter',
   *    nested: [
   *      {
   *        key: 'keyToFilter',
   *        value: 'valueOfFilter',
   *        not: true,
   *      },
   *      ...
   *    ],
   *  },
   *  ...
   *]
   *```
   *
   * @param {object[]} filters array of filter
   * @param {*} not
   */
  _buildFakeFilters(filters, not) {
    const fakeFilters = [];
    for (let i = 0; i < filters.length; i += 1) {
      const filter = filters[i];
      // If find directly term or terms
      if (filter.term || filter.terms) {
        const [key, value] = Object.entries(filter.term || filter.terms).pop();
        fakeFilters.push({ key, value, not: not || false });
      } else if (filter.bool) {
        /**
         * If contain sub request, compute this with an other call of _buildFakeFilters
         * Push spread result to flat the object of filters
         */
        if (Array.isArray(filter.bool.must)) {
          fakeFilters.push(...this._buildFakeFilters(filter.bool.must));
        } else if (Array.isArray(filter.bool.must_not)) {
          fakeFilters.push(...this._buildFakeFilters(filter.bool.must_not, true));
        }
      } else if (filter.nested && filter.nested.path && filter.nested.query) {
        // If contain nested request compute this with an call to _buildFakeQuery
        let nested = this._buildFakeQuery(filter.nested.query, not);
        nested = nested.map((f) => {
          // Remove nested path in all nested query
          f.key = f.key.replace(`${filter.nested.path}.`, '');
          return f;
        });
        // Add nested filters with base path
        fakeFilters.push({
          key: filter.nested.path,
          nested,
        });
      }
    }
    return fakeFilters;
  }

  /**
   * To get the request in the desired format (kuzzle by default)
   * Kuzzle format is not transformed
   *
   * @see _getFakeQuery to get an example of FakeQuery
   *
   * @param {string} format Desired request format
   *
   * @return {object} Request in desired format
   * @throws {InvalidArgumentException} Will throw an error when type doesn't exist
   */
  getQuery(format = 'kuzzle') {
    switch (format) {
      case 'fake':
        return this._buildFakeQuery(this.query || { bool: { must: [] } });

      case 'kuzzle':
        return this.query;

      default:
        throw new Error(`The format "${format}" is not supported`);
    }
  }

  /**
   * Add an filter to the query
   *
   * @param {string} path Path to add the filter
   * @param {object} template Template object of filter
   * @param {*} value Value of filter
   * @param {boolean} multiple Define if the filter it's added at path or replace the other filters
   */
  addFilter(path, template, value, multiple = false) {
    if (!_.isEmpty(value)) {
      let filterQuery = new QueryBuilder(template, { value }).getQuery();

      if (multiple) {
        let currentQuery = _.get(this.query, path, []);
        if (!Array.isArray(currentQuery)) {
          currentQuery = [currentQuery];
        }
        filterQuery = [...currentQuery, filterQuery];
      }

      this.query = _.set(this.query, path, filterQuery);
    }
  }

  addSort(template, order) {
    // If sort are not already initialized
    if (!Array.isArray(this.query.sort)) {
      this.query.sort = [];
    }
    this.query.sort.push(new QueryBuilder(template, { order }).getQuery());
  }
}

/**
 * Expose QueryBuilder class to compare with instanceof
 */
export const QueryBuilderClass = QueryBuilder;
/**
 * Créate a QueryBuilder instance
 *
 * @param {object} template Template of query
 * @param {object} values Values to compute in template
 *
 * @return {QueryBuilder} Return a QueryBuilder instance
 */
export function queryBuilder(template, values) {
  return new QueryBuilder(template, values);
}
/**
 * Legacy: Direct compute values in template an get desired format
 *
 * @deprecated since version 1.12.0
 *
 * @param {object} template Template of query
 * @param {object} values Values to compute in template
 * @param {string} format Desired request format
 */
export default function directQuery(template, values, format = 'kuzzle') {
  return new QueryBuilder(template, values).getQuery(format);
}
