const applications = [
  {
    id: 'ATR-Forizons',
    customer: 'Forizons',
    type: 'myAsset',
    name: 'ATR Tracking dashboard',
    description: 'The ATR_Forizons app',
    logo: 'ATR_Forizons.png',
    expandVisualizationSection: true,
    expandConfigurationSection: false,
    pages: [{
      route: {
        path: '/', component: './Home.vue', name: 'Home', icon: 'mdi-apps', menu: true,
      },
      options: {
        permissions: ['read', 'search'],
        fields: {
          search: [{
            fid: 'device-name', object: 'device', path: { fields: ['userFriendlyName', 'pid', 'poi'] }, search: true, label: 'pages.device details.name',
          }],
          read: [{
            fid: 'device-messages',
            object: 'device',
            component: 'layoutField',
            mapLayers: [{
              alias: 'sites',
              object: 'poi',
              label: 'sites',
              queryParams: { level: 'SITE', limit: 100 },
              type: 'shape',
              path: 'area',
              fields: [{ fid: 'site-name', path: 'name', label: 'pages.site details.name' }, {
                fid: 'site-type', path: 'data.type', format: { prefix: 'siteTypes', type: 'localized' }, label: 'pages.site details.type',
              }],
            }],
            fields: {
              search: [{
                fid: 'device-name', component: 'autocompleteField', object: 'device', path: 'pid', default: [], search: true, label: 'pages.device details.name',
              }, {
                fid: 'device-site', component: 'autocompleteField', object: 'device', path: 'poi', description: 'pages.device details.searchSite', label: 'pages.device details.site', default: [], multiple: true, labelPath: 'town',
              }],
              read: [{
                fid: 'message-location', type: 'marker', path: 'coords', display: ['Map'], label: 'pages.device details.messages.localization',
              }, {
                fid: 'device-name', path: { fields: ['userFriendlyName', 'pid'] }, display: ['Table', 'Map'], label: 'pages.device details.name', sortable: true,
              }, {
                fid: 'device-id', path: 'pid', label: 'pages.device details.device', display: ['Table', 'Map'], sortable: true,
              }, {
                fid: 'device-area', path: 'poi', display: ['Table', 'Map'], format: { defaultValue: 'N/A' }, label: 'pages.device details.area', sortable: true,
              }, {
                fid: 'device-location-dateTime', path: 'locationTimestamp', display: ['Table', 'Map'], format: { type: 'dateTime' }, label: 'pages.device details.lastLocalization', sortable: true,
              }, {
                fid: 'device-last-message', path: 'lastTimestamp', display: ['Table', 'Map'], format: { type: 'dateTime' }, label: 'pages.device details.lastMessage', sortable: true,
              }, {
                fid: 'device-details-link', path: 'pid', display: ['Table', 'Map'], format: { isExternal: false, urlPrefix: '/item/business-events/', type: 'linkObject' }, label: 'pages.device details.details', internalLink: true,
              }],
            },
            defaultLayout: 'bothTableAndMap',
            layouts: {
              bothTableAndMap: {
                components: ['Table', 'Map'], xs12: 'bothTableAndMap', icon: 'mdi-table-merge-cells', xs6: 'bothTableAndMap', label: 'misc.bothTableAndMap',
              },
            },
            options: { perPage: 100, height: -1 },
          }],
        },
        object: 'applicationDevices',
      },
    }, {
      route: {
        path: '/list/devices', component: './generic/List.vue', name: 'Devices', icon: 'ffly4u-device', menu: true, configuration: true,
      },
      options: {
        itemRoute: '/item/devices',
        permissions: ['search', 'read', 'update'],
        objectId: 'id',
        fields: {
          search: [{
            fid: 'device-name', path: { fields: ['userFriendlyName', 'pid'] }, search: true, label: 'pages.device details.name',
          }, {
            fid: 'device-site', label: 'pages.device details.site', description: 'pages.device details.searchSite', component: 'autocompleteField', object: 'poi', queryParams: { level: 'ALL' }, path: 'poiId', labelPath: 'name', multiple: true,
          }, {
            fid: 'device-last-message-after', path: 'after', component: 'dateTimeField', description: 'pages.device details.searchLastMessageAfter', label: 'pages.device details.lastMessageAfter',
          }, {
            fid: 'device-last-message-before', path: 'before', component: 'dateTimeField', description: 'pages.device details.searchLastMessageBefore', label: 'pages.device details.lastMessageBefore',
          }],
          read: [{
            fid: 'device-id', path: 'pid', label: 'pages.device details.device', sortable: true,
          }, {
            fid: 'device-name', path: { fields: ['userFriendlyName', 'pid'] }, label: 'pages.device details.name', sortable: true,
          }, {
            fid: 'device-area', path: 'poi', format: { defaultValue: 'N/A' }, label: 'pages.device details.area', sortable: true,
          }, {
            fid: 'device-location-dateTime', path: 'locationTimestamp', format: { type: 'dateTime' }, label: 'pages.device details.lastLocalization', sortable: true,
          }, {
            fid: 'device-last-message', path: 'lastTimestamp', format: { type: 'dateTime' }, label: 'pages.device details.lastMessage', sortable: true,
          }],
        },
        object: 'device',
      },
    }, {
      route: {
        path: '/item/devices/:objectId', component: './generic/Item.vue', name: 'Device Details', menu: false,
      },
      options: {
        object: 'device',
        permissions: ['read', 'update', 'create'],
        name: { path: { fields: ['userFriendlyName', 'pid'] } },
        fields: {
          write: [{
            fid: 'device-name', path: 'userFriendlyName', component: 'textField', description: 'pages.device details.editName', label: 'pages.device details.name',
          }],
        },
      },
    }, {
      route: {
        path: '/list/business-events', component: './generic/List.vue', name: 'Business Events', icon: 'mdi-history', menu: true, visualization: true,
      },
      options: {
        itemRoute: '/item/business-events',
        permissions: ['search', 'read'],
        canImport: false,
        fields: {
          search: [{
            fid: 'device-name', path: { fields: ['userFriendlyName', 'pid'] }, search: true, label: 'pages.device details.name',
          }, {
            fid: 'device-site', component: 'autocompleteField', object: 'poi', queryParams: { level: 'SITE' }, path: 'poiId', description: 'pages.device details.searchSite', label: 'pages.device details.site', default: [], multiple: true, labelPath: 'name',
          }, {
            fid: 'device-last-message-after', path: 'after', component: 'dateTimeField', description: 'pages.device details.searchLastMessageAfter', label: 'pages.device details.lastMessageAfter',
          }, {
            fid: 'device-last-message-before', path: 'before', component: 'dateTimeField', description: 'pages.device details.searchLastMessageBefore', label: 'pages.device details.lastMessageBefore',
          }],
          read: [{
            fid: 'device-id', path: 'pid', label: 'pages.device details.device', sortable: true,
          }, {
            fid: 'device-name', path: { fields: ['userFriendlyName', 'pid'] }, label: 'pages.device details.name', sortable: true,
          }, {
            fid: 'device-area', path: 'poi', format: { defaultValue: 'N/A' }, label: 'pages.device details.area', sortable: true,
          }, {
            fid: 'device-location-dateTime', path: 'locationTimestamp', format: { type: 'dateTime' }, label: 'pages.device details.lastLocalization', sortable: true,
          }, {
            fid: 'device-last-message', path: 'lastTimestamp', format: { type: 'dateTime' }, label: 'pages.device details.lastMessage', sortable: true,
          }],
        },
        object: 'device',
      },
    }, {
      route: {
        path: '/item/business-events/:objectId', component: './generic/Item.vue', name: 'Business Events Details', menu: false,
      },
      options: {
        object: 'device',
        permissions: ['read'],
        name: { path: { fields: ['userFriendlyName', 'pid'] } },
        fields: {
          read: [{
            fid: 'device-timeline-and-messages',
            component: 'layoutField',
            alias: 'stateDevice',
            object: 'device',
            components: { Table: {}, Map: {}, timeLineMapField: {} },
            mapLayers: [],
            timelineMapLayers: [{
              alias: 'sites',
              object: 'poi',
              label: 'sites',
              queryParams: { level: 'SITE', limit: 50 },
              type: 'shape',
              path: 'area',
              fields: [{ fid: 'site-name', path: 'name', label: 'pages.site details.name' }, {
                fid: 'site-type', path: 'data.type', format: { prefix: 'siteTypes', type: 'localized' }, label: 'pages.site details.type',
              }],
            }],
            seriesData: { issueId: 'issueUpdateId', issueEvent: 'issueUpdateState' },
            defaultLayout: 'map',
            layouts: {
              map: {
                components: ['timeLineMapField'], xs12: 'map', icon: 'mdi-map', xs6: 'map', label: 'misc.timelineMapOnly',
              },
              bothTableAndMap: {
                components: ['Table', 'Map'], xs12: 'bothTableAndMap', icon: 'mdi-table-merge-cells', xs6: 'bothTableAndMap', label: 'misc.bothTableAndMap',
              },
              table: {
                components: ['Table'], xs12: 'table', icon: 'mdi-table-large', xs6: 'both', label: 'misc.tableOnly',
              },
            },
            options: { height: -1 },
            fields: {
              search: [{
                fid: 'device-last-message-after', path: 'generationTimestamp', component: 'dateTimeField', description: 'pages.device details.searchLastMessageAfter', label: 'pages.device details.lastMessageAfter',
              }, {
                fid: 'device-last-message-before', path: 'generationTimestamp', component: 'dateTimeField', default: 'now', description: 'pages.device details.searchLastMessageBefore', label: 'pages.device details.lastMessageBefore',
              }],
              read: [{
                fid: 'message-location', path: 'coords', type: 'marker', display: ['Map'], label: 'pages.device details.messages.localization',
              }, {
                fid: 'message-datetime', path: 'generationTimestamp', display: ['Map', 'Table'], format: { type: 'dateTime' }, label: 'pages.device details.messages.dateTime', sortable: true,
              }, {
                fid: 'device-area', path: 'town', display: ['Map', 'Table'], format: { defaultValue: 'N/A' }, label: 'pages.device details.area', sortable: true,
              }, {
                fid: 'message-localization', path: 'coords', display: ['Table'], format: { type: 'coordinates' }, label: 'pages.device details.messages.localization',
              }, {
                fid: 'device-location-type', path: 'locationTypes', display: ['Map', 'Table'], format: { prefix: 'localizationTypes', type: 'localized' }, label: 'pages.device details.messages.localizationType', sortable: false,
              }, {
                fid: 'message-event', path: 'moving', display: ['Map', 'Table'], format: { defaultValue: 'N/A', prefix: 'pages.device details.messages.events', type: 'localized' }, label: 'pages.device details.messages.event', sortable: true,
              }, {
                fid: 'state-location', type: 'marker', path: 'coords', display: ['TimeLine-Map'], timelineMapField: true, linkedLayer: true,
              }, {
                fid: 'state-datetime', path: 'generationTimestamp', display: ['TimeLine-Map'], timelineMapField: true, format: { template: 'dd MMMM yyyy HH:mm', type: 'dateTime' }, label: 'pages.business events details.states.dateTime',
              }, {
                fid: 'state-timeline-time', path: 'generationTimestamp', display: ['TimeLine'], timelineMapField: true, format: { template: 'HH:mm', type: 'dateTime' }, label: 'pages.business events details.states.time', slot: 'time',
              }, {
                fid: 'state-event-type', path: 'country', display: ['TimeLine'], timelineMapField: true, isImportant: { type: 'regExp', testValue: '(exit_travel|enter_travel)' }, format_disabled: { prefix: 'causes', type: 'localized' }, slot: 'title',
              }, {
                fid: 'state-address', path: '', display: ['TimeLine-Map', 'TimeLine'], timelineMapField: true, format: { type: 'concat', fields: ['town', 'country'] }, slot: 'caption', label: 'pages.business events details.states.address',
              }, {
                fid: 'type-icon', path: 'moving', display: ['TimeLine'], timelineMapField: true, visible: true, format: { condition: { type: 'regExp', testValue: '(EXIT_TRAVEL|ENTER_TRAVEL)' }, alias: { EXIT_TRAVEL: 'mdi-truck-check', ENTER_TRAVEL: 'mdi-truck-delivery' }, type: 'icon' }, isImportant: { type: 'regExp', testValue: '(EXIT_TRAVEL|ENTER_TRAVEL)' }, slot: 'icon-event',
              }, {
                fid: 'device-travel', path: 'moving', display: ['TimeLine'], timelineMapField: true, slot: 'travel',
              }],
            },
          }, {
            fid: 'device-last-message', path: 'lastTimestamp', component: 'dateTimeField', description: 'pages.device details.lastMessageDescription', label: 'pages.device details.lastMessage',
          }],
        },
      },
    },
    {
      route: {
        path: '/list/inventory',
        component: './generic/List.vue',
        name: 'inventory',
        icon: 'mdi-package-variant',
        menu: true,
        visualization: true,
      },
      options: {
        itemRoute: '/list/business-events/?device-site=',
        object: 'poi/inventory',
        queryParams: {
          level: 'SITE',
        },
        permissions: [
          'search',
          'read',
        ],
        canImport: false,
        fields: {
          search: [
            {
              fid: 'inventory-search',
              path: {
                fields: [
                  'name',
                  'count',
                ],
              },
              search: true,
              label: 'inventorySearch',
            },
            {
              fid: 'inventory-count-superior',
              path: 'count_superior',
              component: 'sliderField',
              options: {
                min: 0,
                max: 10,
                step: 1,
              },
              default: 0,
              description: 'pages.inventory details.searchCountSuperior',
              label: 'pages.inventory details.countSuperior',
            },
            {
              fid: 'inventory-count-inferior',
              path: 'count_inferior',
              component: 'sliderField',
              options: {
                min: 0,
                max: 100,
                step: 1,
              },
              default: 'max',
              description: 'pages.inventory details.searchCountInferior',
              label: 'pages.inventory details.countInferior',
            },
          ],
          read: [
            {
              fid: 'inventory-name',
              path: 'name',
              label: 'pages.inventory details.name',
              sortable: true,
            },
            {
              fid: 'inventory-count',
              path: 'count',
              label: 'pages.inventory details.count',
              sortable: true,
            },
          ],
        },
      },
    },
    {
      route: {
        path: '/list/sites', component: './generic/List.vue', name: 'Sites', icon: 'mdi-vector-polygon', menu: true, configuration: true,
      },
      options: {
        itemRoute: '/item/sites',
        object: 'poi',
        queryParams: { level: 'SITE' },
        permissions: ['create', 'search', 'read', 'update', 'delete'],
        canImport: false,
        fields: {
          read: [{
            fid: 'site-name', path: 'name', label: 'pages.site details.name', sortable: true,
          }],
        },
      },
    }, {
      route: {
        path: '/item/sites/:objectId', component: './generic/Item.vue', name: 'Site Details', menu: false,
      },
      options: {
        itemRoute: '/item/sites',
        permissions: ['read', 'update', 'create'],
        name: { path: 'name' },
        queryParams: { level: 'SITE' },
        fields: {
          write: [{
            fid: 'site-name', path: 'name', component: 'textField', description: 'pages.site details.editName', label: 'pages.site details.name', required: true,
          }, {
            fid: 'site-shape',
            path: 'area',
            component: 'mapField',
            editable: 'shapes',
            mapLayers: [{
              alias: 'platforms',
              object: 'poi',
              label: 'platforms',
              queryParams: { level: 'AREA' },
              type: 'shape',
              path: 'area',
              fields: [{ fid: 'site-name', path: 'name', label: 'pages.site details.name' }, {
                fid: 'site-type', path: 'data.type', format: { prefix: 'siteTypes', type: 'localized' }, label: 'pages.site details.type',
              }],
            }],
            options: { height: '700px' },
            description: 'pages.site details.editShape',
            label: 'pages.site details.shape',
            required: true,
          }],
        },
        object: 'poi',
      },
    },
    {
      route: {
        path: '/list/beacons',
        component: './generic/List.vue',
        name: 'Beacons',
        icon: 'mdi-radio-tower',
        menu: true,
        configuration: true,
      },
      options: {
        itemRoute: '/item/beacons',
        object: 'beacon',
        permissions: [
          'search',
          'read',
          'update',
        ],
        canImport: false,
        fields: {
          read: [{
            fid: 'beacon-id',
            path: 'pid',
            label: 'pages.beacon details.id',
            sortable: true,
          },
          {
            fid: 'beacon-name',
            path: 'userFriendlyName',
            label: 'pages.beacon details.name',
            sortable: true,
          },
          {
            fid: 'beacon-site',
            path: 'poi',
            label: 'pages.beacon details.site',
            sortable: true,
          },
          ],
        },
      },
    },
    {
      route: {
        path: '/item/beacons/:objectId',
        component: './generic/Item.vue',
        name: 'Beacon Details',
        menu: false,
      },
      options: {
        itemRoute: '/item/beacons',
        object: 'beacon',
        permissions: [
          'read',
          'update',
        ],
        name: {
          path: {
            fields: [
              'userFriendlyName',
              'pid',
              'poi',
            ],
          },
        },
        fields: {
          write: [{
            fid: 'beacon-id',
            path: 'pid',
            component: 'textField',
            description: 'pages.beacon details.editId',
            label: 'pages.beacon details.id',
            required: true,
            noEdit: true,
          },
          {
            fid: 'beacon-name',
            path: 'userFriendlyName',
            component: 'textField',
            description: 'pages.beacon details.editName',
            label: 'pages.beacon details.name',
            required: true,
          },
          {
            fid: 'beacon-site',
            label: 'pages.beacon details.site',
            description: 'pages.beacon details.editSite',
            component: 'autocompleteField',
            path: 'poiId',
            autocompletePath: 'name',
            labelPath: 'name',
            object: 'poi',
            queryParams: {
              level: 'SITE',
            },
          },
          ],
        },
      },
    }],
    locales: {
      fr: {
        pages: {
          'device details': {
            name: 'MSN',
            device: 'N° tracker',
            area: 'Zone',
          },
          'site details': {},
          'platform details': {},
          'beacon details': {},
        },
      },
      en: {
        pages: {
          'device details': {
            name: 'MSN',
            device: 'N° tracker',
          },
          'site details': {},
          'platform details': {},
          'beacon details': {},
        },
      },
    },
  },

];

export default {
  id: 'decoset',
  name: 'ffly4u',
  applications,
};
