import NotFound from '../views/common/NotFound.vue';

const views = {};

const viewFiles = require.context('../views', true, /[A-Za-z0-9-_,\s]+\.vue$/i, 'lazy');
viewFiles.keys().forEach((key) => {
  views[key] = () => viewFiles(key);
});

export default (view) => {
  if (Object.keys(views).indexOf(view) === -1) {
    return NotFound;
  }

  return views[view];
};
