<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 lg3>
        <v-card class="pb-6">
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <DropdownTranslate v-if="!userLoggedIn" />
            </v-flex>
          </v-layout>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <h1>{{ $t('pages.notFound.title') }}</h1>
            </v-flex>
          </v-layout>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <p>
                {{ $t('pages.notFound.description') }}
              </p>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center row fill-height>
            <v-btn
              type="button"
              class="white--text"
              to="/"
              color="primary">{{ $t('pages.notFound.button') }}
            </v-btn>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
  computed: {
    userLoggedIn() {
      return !!this.$store.state.auth.currentUser;
    },
  },
  metaInfo() {
    return {
      title: this.$t('pages.notFound.title'),
    };
  },
};
</script>
