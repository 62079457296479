<template>
  <v-snackbar
    style="position:relative; margin-top: 10px;"
    :color="color"
    v-model="snackbar"
    bottom
    right
    :timeout="timeout"
  >
    <v-layout row justify-center align-center mx-3>
      <v-flex xs3 sm2 v-if="icon || loading">
        <v-icon
          v-if="icon"
          color="white"
        >
          {{ icon }}
        </v-icon>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-flex>
      <v-flex :class="{'xs9': icon || loading, 'sm10': icon || loading, 'xs12': !icon && !loading}">
        <h3 v-if="title">{{ title }}</h3>
        {{ description }}
        <v-btn
          style="float: right"
          text
          v-on:click="close()"
        >
          {{ $t('misc.close') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-snackbar>
</template>
<script>
export default {
  name: 'snackbar',
  props: {
    id: {
      type: String,
    },
    color: {
      type: String,
      default: 'info',
    },
    timeout: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      snackbar: true,
    };
  },
  methods: {
    close() {
      this.$store.commit('snackbars/REMOVE_MESSAGE', this.id);
    },
  },
  mounted() {
    if (this.timeout > 0) {
      setTimeout(() => {
        this.close();
      }, this.timeout + 500);
    }
  },
};
</script>
