const state = {
  messages: [],
};

const actions = {};

const mutations = {
  ADD_MESSAGE: (state, data) => {
    state.messages.push(data);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
