import { Cache } from 'aws-amplify';
import Storage from '@/misc/Storage';
import Backend from '@/backend';
import _ from '@/misc/lodash';
import userPreferences from '@/backend/static-data/user';

const FFLY4U_STORAGE_KEY = 'currentUserffly4u';

const state = {
  currentUser: null,
  currentUserffly4u: Storage.getObject(FFLY4U_STORAGE_KEY, true) || userPreferences,
  waitingConnect: false,
  online: false,
  cognitoUser: null,
  permissions: Storage.getObject('user-permissions') || null,
};

const actions = {
  LOGIN: async ({ dispatch }, { credentials }) => {
    // let jwt;
    try {
      const { loggedIn, redirect } = await Backend().auth.login(credentials);
      if (loggedIn) {
        dispatch('FETCH_CURRENT_USER');
      }
      return { loggedIn, redirect };
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      throw error;
    }
  },
  FETCH_CURRENT_USER: async ({ commit }) => {
    try {
      const credentials = await Backend().auth.getMyCredentials();
      // localStorage.setItem('kuid', credentials.kuid);
      commit('SET_CURRENT_USER', credentials);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      const data = {
        id: null,
        title: 'Current user',
        description: 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
      // localStorage.removeItem('kuid');
    }
  },
  FETCH_PERMISSIONS: async ({ commit }) => {
    try {
      const permissions = await Backend().read('perm/user/me', undefined, undefined);
      commit('SET_USER_PERMISSIONS', permissions);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      const data = {
        id: null,
        title: 'Permissions utilisateur',
        description: 'Une erreur est survenue',
        color: 'error',
        timeout: 10000,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
  },
  LOG_OUT: async () => {
    try {
      await Backend().auth.logout();
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Logout error', error);
      }
    }
    window.location = '/';
  },
  CHECK_TOKEN: async ({ dispatch, getters }) => {
    const isConnected = await Backend().auth.checkToken();
    if (!isConnected) {
      return false;
    }

    if (!getters.currentUserffly4u) {
      await dispatch('FETCH_CURRENT_USER');
    }
    return true;
  },
  REGISTER: async (_context, {
    email,
    username,
    password,
  }) => {
    await Backend().auth.register(email, username, password);
  },
  RESET: async ({ commit }, { username, admin = false }) => {
    try {
      await Backend().auth.forgotPassword(username);
    } catch (error) {
      if (admin) {
        const data = {
          id: null,
          title: this.$t('auth.passwordReset.title'),
          description: 'An error occurred',
          color: 'error',
          timeout: -1,
        };
        commit('snackbars/ADD_MESSAGE', data, { root: true });
      } else {
        throw error;
      }
    }
  },
  INVITE: async ({ commit }, { objectId }) => {
    try {
      await Backend().query({
        controller: 'environment-manager/usersController',
        action: 'invite',
        objectId,
      });
    } catch (error) {
      const data = {
        id: null,
        title: 'Resend invitation',
        description: 'An error occurred',
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
  },
  VALIDATION_EMAIL: async (_context, { email, validationToken }) => {
    await Backend().query({
      controller: 'environment-manager/usersController',
      action: 'validate',
      body: {
        validationToken,
        email,
      },
    });
  },
  NEW_PASSWORD: async ({ state, commit }, password) => {
    try {
      const { changed, redirect } = await Backend().auth.newPassword(password, state.cognitoUser);
      if (changed) {
        commit('UNSET_COGNITO_USER');
      }
      return { changed, redirect };
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log(error);
      }
      throw error;
    }
  },
  RESET_PASSWORD: async (_context, {
    username,
    password,
    resetToken,
  }) => {
    await Backend().auth.forgotPasswordSubmit(username, resetToken, password);
  },
  UPDATE_SELF: async ({ commit, state }, params) => {
    if (!state.currentUserffly4u) {
      return;
    }
    const source = { ...state.currentUserffly4u._source, ...params };
    commit('SET_USER_FFLY4U_SOURCE', source);
    // console.log(source);
  },
  SET_SELF: async ({ commit, state }, { path, value }) => {
    if (!state.currentUserffly4u) {
      return;
    }
    const user = { ...state.currentUserffly4u, [path]: value };
    commit('SET_CURRENT_USER_FFLY4U', user);
  },
  CLEAR_ALL_DATA: async ({ commit, dispatch }) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('All data were cleared');
    }
    Backend().setJwtToken(null);
    // localStorage.removeItem('kuid');
    // localStorage.removeItem('doNotConsent');
    commit('UNSET_CURRENT_USER');
    commit('UNSET_USER_PERMISSIONS');
    // commit('UNSET_CURRENT_USER_FFLY4U');
    dispatch('settings/CLEAR_SELECTED_APPLICATION', null, { root: true });
    Cache.clear();
  },
};

const mutations = {
  UNSET_CURRENT_USER: (state) => {
    state.currentUser = null;
  },
  UNSET_CURRENT_USER_FFLY4U: (state) => {
    state.currentUserffly4u = null;
  },
  UNSET_COGNITO_USER: (state) => {
    state.cognitoUser = null;
  },
  SET_CURRENT_USER: (state, user) => {
    state.currentUser = user;
  },
  SET_CURRENT_USER_FFLY4U: (state, user) => {
    state.currentUserffly4u = user;
    Storage.setObject(FFLY4U_STORAGE_KEY, state.currentUserffly4u, true);
  },
  SET_USER_FFLY4U_SOURCE: (state, source) => {
    _.merge(state.currentUserffly4u._source, source);
    Storage.setObject(FFLY4U_STORAGE_KEY, state.currentUserffly4u, true);
  },
  SET_COGNITO_USER: (state, user) => {
    state.cognitoUser = user;
  },
  SET_ONLINE: (state) => {
    state.online = true;
  },
  SET_OFFLINE: (state) => {
    state.online = false;
  },
  SET_WAITING_CONNECT: (state) => {
    state.waitingConnect = true;
  },
  UNSET_WAITING_CONNECT: (state) => {
    state.waitingConnect = false;
  },
  SET_USER_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
    Storage.setObject('user-permissions', state.permissions);
  },
  UNSET_USER_PERMISSIONS: (state) => {
    state.permissions = null;
  },
  CLEAR_USER_FFLY4U_PREFS: (state) => {
    const res = {};
    Object.keys(state.currentUserffly4u).forEach((key) => {
      if (!key.toLocaleLowerCase?.().includes('preference')) {
        res[key] = state.currentUserffly4u[key];
      }
    });
    state.currentUserffly4u = res;
    Storage.setObject(FFLY4U_STORAGE_KEY, state.currentUserffly4u, true);
  },
};

const getters = {
  currentUser: (state) => state.currentUser,
  currentUserffly4u: (state) => state.currentUserffly4u,
  isAdmin: (state) => (state.currentUser ? state.currentUser.isAdmin : false),
  isExporter: (state) => (state.currentUser ? state.currentUser.isExporter : false),
  userPermissions: (state) => state.permissions,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
