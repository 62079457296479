export default {
  id: 'test-user',
  _source: {
    customer: 'ffly4u',
    active: true,
    email: 'admin@ffly4u.ffly4u',
    name: 'admin@ffly4u.ffly4u',
    profiles: [
      {
        application: 'test',
        profile: 'admin-ffly4u',
      },
    ],
    data: [
      {
        name: 'firstName',
        type: 'text',
        text: 'admin',
      },
      {
        name: 'lastName',
        type: 'text',
        text: 'ffly4u',
      },
    ],
    timeLinePreferences: {
      '/item/business-events/:objectId': {
        viewType: 1,
      },
    },
    locale: {
      name: 'locale',
      type: 'keyword',
      keyword: 'en',
    },
    metadata: [],
  },
};
