/**
 * Generated a download link and automatically laucnt the download
 * @param {Blob} blob a blob
 * @param {string} filename the name of the file to generate. Ex: users.csv
 * @returns A link to download again
 */
export function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);
  // Create a new anchor element
  const a = document.createElement('a');
  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener?.('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}

/**
 * Generate a csv header: a *separator* separated string
 * @param {Array.<string>} labels an array of csv labels Ex: ["name", "job"]
 * @param {string} separator the csv separator. Ex: ","
 * @returns {string} csv header: Ex: "name,job"
 */
export function createCsvLabels(labels, separator) {
  let row = '';
  try {
    row = labels.reduce((acc, label) => `${acc}"${label}"${separator}`, '');
    row = row.slice(0, -1);
    return `${row}\r\n`;
  } catch (err) {
    return 'error';
  }
}

/**
 * Generate a csv content: a *separator* separated string
 * @param {Array.<Object.<string, any>>} arr the array of csv content.
 * Ex: [{name: "john"}, {name: "bob"}]
 * @param {Array.<string>} labels an array of csv labels Ex: ["name"]
 * @param {string} separator the csv separator. Ex: ","
 * @returns {string} csv content: Ex: "john\r\nbob\r\n"
 */
export function createCsvContent(arr, labels, separator) {
  let row = '';
  let content = '';
  let type = '';

  try {
    arr.forEach((line) => {
      row = labels.reduce((acc, label) => {
        type = typeof line[label];
        if (type === 'number' || type === 'float') {
          return `${acc}${line[label]}${separator}`;
        }
        if (type === 'undefined') {
          return `${acc}""${separator}`;
        }
        return `${acc}"${line[label]}"${separator}`;
      }, '');
      row = row.slice(0, -1);
      content += `${row}\r\n`;
    });
    return content;
  } catch (err) {
    return 'error';
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 *
 * @param {*} object the object from which to check
 * @param {string} key the key to check for existance
 * @returns {Boolean} true if the key exists into the object or not
 */
export function ObjHasKey(object, key) {
  return Object.prototype.hasOwnProperty.call(object, key);
}

export default {
  downloadBlob,
  createCsvLabels,
  createCsvContent,
  capitalizeFirstLetter,
};
