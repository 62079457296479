import { API, Cache } from 'aws-amplify';

const doNotCacheRoutes = ['authent', 'config', 'perm/user/me'];

/**
 * hash a string
 *
 * @param {string} string to hash
 *
 * @returns { string } the hashed resulting number (positive or negeative)
 * in string  (ex: '-12', '84282' ...)
 */
// eslint-disable-next-line class-methods-use-this
function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const character = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + character;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }
  return `${hash}`;
}

/**
 * Generate a unique key corresponding to the request
 *
 * @param {string} path the path of the request
 * @param {string} queryParams the request params
 * @param {string} body the body of the request
 *
 * @returns { string } the key corresponding to a hash of the request
 */
function generateCacheKey(path, queryParams, body) {
  const key = `${path}-${JSON.stringify(queryParams)}-${JSON.stringify(body)}`;
  return hashCode(key);
}

/**
 * Read request from aws Cache if it exists or it is not yet expired
 *
 * @param {string} path the path of the request
 * @param {string} queryParams the request params
 * @param {string} body the body of the request
 *
 * @returns { object | null } the cached object or null if not found or expired
 */
function getCacheItem(path, queryParams, body) {
  const key = generateCacheKey(path, queryParams, body);
  return Cache.getItem(key);
}

/**
 * Save the request to aws Cache
 *
 * @param {string} path the path of the request
 * @param {string} queryParams the request params
 * @param {string} body the body of the request
 * @param {string} status the status of the request response
 * @param {string} data the data of the request response
 *
 */
function maybeStoreToCache(path, queryParams, body, status, data) {
  if (status === 200 && !doNotCacheRoutes.includes(path)) {
    const key = generateCacheKey(path, queryParams, body);
    const cache = {
      path,
      queryParams,
      body,
      data,
    };
    Cache.setItem(key, cache);
  }
}

/**
 * Invalidate cache based on request path and response status
 *
 * @param {Number} status the request response status
 * @param {string} path the path of the request
 *
 */
function maybeInvalidateCache(status, path) {
  if (status === 200) {
    let cacheKeys = Cache.getAllKeys();
    const pathParts = path.split('/');
    cacheKeys = cacheKeys.filter((key) => {
      const item = Cache.getItem(key);
      // Amplify sometimes return a null value for keys that it
      // previously gave. That's Weird! So we check if it's null.
      if (item) {
        let removeItem = false;
        pathParts.forEach((el) => {
          if (item.path.includes(el)) {
            removeItem = true;
          }
        });
        return removeItem;
      }
      return true;
    });
    cacheKeys.forEach((key) => Cache.removeItem(key));
  }
}
export default {
  /**
   * Makes an Amplify API request based on requestType (GET, POST, PUT) and
   * cache it result.
   * The function returns the cached value of the request if it was already
   * cached.
   *
   * @param {{
   *    path: string,
   *    params: Record<string, string|object>,
   *    body: Record<string, string|object>
   *  }} cacheData the path of the request
   * @param {{
   *    apiName: string,
   *    uri: string,
   *    params: object
   *  }} requestData the request params
   * @param {string} requestType the body of the request: GET, POST, or PUT
   *
   * @returns {{ status: number, data: object }} The cached data
   * or the result of the http request
   */
  async makeCachedRequest(cacheData, requestData, requestType) {
    let status = 204;
    let data = { currentResult: 0, totalResult: 0, body: [] };
    const { path, queryParams, body } = cacheData;
    const item = getCacheItem(path, queryParams, body);
    if (item) {
      data = item.data;
      status = 200;
    } else {
      const { apiName, uri, params } = requestData;
      switch (requestType.toUpperCase()) {
        case 'GET':
          ({ data, status } = await API.get(apiName, uri, params));
          break;
        case 'POST':
          ({ data, status } = await API.post(apiName, uri, params));
          if (path.endsWith('create')) {
            maybeInvalidateCache(status, path);
          }
          break;
        case 'PUT':
          ({ data, status } = await API.put(apiName, uri, params));
          break;
        default:
          break;
      }
      maybeStoreToCache(path, queryParams, body, status, data);
    }
    return { status, data };
  },

  /**
   * Makes an Amplify API put request and invalidate cache concerned
   * by this update request
   * The function returns the result of the request.
   *
   * @param {string} path the path of the request
   * @param {{
   *    apiName: string,
   *    uri: string,
   *    params: object
   *  }} requestData the request params
   *
   * @returns {{ status: number, data: object }} The result of the HTTP put request
   */
  async putAndInvalidateCache(path, requestData) {
    const { apiName, uri, params } = requestData;
    const { data, status } = await API.put(apiName, uri, params);
    maybeInvalidateCache(status, path);
    return { status, data };
  },
  /**
   * Makes an Amplify API put request and invalidate cache concerned
   * by this update request
   * The function returns the result of the request.
   *
   * @param {string} path the path of the request
   * @param {{
   *    apiName: string,
   *    uri: string,
   *    params: object
   *  }} requestData the request params
   * @param {string} requestType the body of the request: GET, POST, PUT, or DELETE
   *
   * @returns {{ status: number, data: object }} The result of the HTTP put request
   */
  async makeRequestAndInvalidateCache(path, requestData, requestType) {
    let status = 204;
    let data = { currentResult: 0, totalResult: 0, body: [] };
    const { apiName, uri, params } = requestData;
    switch (requestType.toUpperCase()) {
      case 'GET':
        ({ data, status } = await API.get(apiName, uri, params));
        break;
      case 'POST':
        ({ data, status } = await API.post(apiName, uri, params));
        break;
      case 'PUT':
        ({ data, status } = await API.put(apiName, uri, params));
        break;
      case 'DELETE':
        ({ data, status } = await API.del(apiName, uri, params));
        break;
      default:
        break;
    }
    maybeInvalidateCache(status, path);
    return { status, data };
  },
};
