import BackendInterface from './BackendInterface';
import BaseController from './controllers/BaseController';

export default class BaseBackend extends BackendInterface {
  store = null

  constructor(store) {
    super();
    this.store = store;
  }

  /**
   * Use an controller for backend
   *
   * @param {BaseController} ControllerClass
   * @param {string} accessor
   */
  useController(ControllerClass, accessor = undefined) {
    if (!(ControllerClass.prototype instanceof BaseController)) {
      throw new Error('Controllers must inherits from the BaseController class.');
    }

    if (!(typeof accessor === 'string' && accessor.length > 0)) {
      throw new Error(`"${accessor}" is not an valid accessor name`);
    }

    if (typeof this[accessor] !== 'undefined') {
      throw new Error(`"${accessor}" is already defined`);
    }

    const controller = new ControllerClass(this);

    if (controller.backend !== this) {
      throw new Error('You must pass the Backend instance to the parent constructor.');
    }
    this[accessor] = controller;
  }
}
