<template>
  <div style="position:fixed; bottom: 20px; right: 20px;z-index: 1000;">
    <Snackbar
      v-for="message in messageSnackbars"
      :key="message.id"
      :color="message.color"
      :title="message.title"
      :description="message.description"
      :timeout="message.timeout"
      :icon="message.icon"
      :loading="message.loading"
      :id="message.id"
    />
  </div>
</template>

<script>
import Snackbar from './Snackbar.vue';

export default {
  data() {
    return {};
  },
  computed: {
    messageSnackbars() {
      return this.$store.state.snackbars.messages;
    },
  },
  components: {
    Snackbar,
  },
};
</script>
