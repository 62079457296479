<template>
  <v-layout align-center justify-end fill-height>
    <v-flex
      style="min-width:120px"
      xs4 sm3 md3>
      <v-select
        v-model="$i18n.locale"
        :items="langs"
        :item-text="getLabel"
        item-value="value"
      >
      </v-select>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'DropdownTranslate',
  data() {
    return {
      langs: this.getLanguages(),
    };
  },
  methods: {
    getLanguages() {
      return Object.keys(this.$i18n.messages).map((lang) => (
        {
          value: lang,
        }
      ));
    },
    getLabel(item) {
      return this.$t(`languages.${item.value}`);
    },
  },
  watch: {
    '$store.state.settings.locales': function w() {
      this.langs = this.getLanguages();
    },
  },
};
</script>
