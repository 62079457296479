import Vue from 'vue';
import Vuex from 'vuex';
import LogRocket from 'logrocket';
import logrocketPlugin from 'logrocket-vuex';
// eslint-disable-next-line import/extensions
import Worker from '@/misc/shapesWorker.worker.js';
import { capitalizeFirstLetter } from '@/misc/utils';
import auth from './auth';
import settings from './settings';
import crud from './crud';
import fakeCrud from './fakeCrud';
import snackbars from './snackbars';
import realtime from './realtime';

const worker = new Worker();

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    settings,
    snackbars,
    realtime,
  },
  state: {
    shapesMarkers: null,
  },
  mutations: {
    SET_SHAPES_MARKERS: (state, shapesMarkers) => {
      state.shapesMarkers = shapesMarkers;
    },
  },
  actions: {
    LOAD_CRUD({ commit }, { data }) {
      if (typeof data === 'object') {
        this.registerModule('crud', fakeCrud);
        commit('crud/INIT_DATA', data);
      } else {
        this.registerModule('crud', crud);
      }
    },
    /**
     * generate shapes markers in a web worker then put it in shapesMarkers object
     */
    GENERATE_MARKERS: (_, shapes) => {
      worker.postMessage({ shapes });
    },
  },
  getters: {
    shapesMarkers: (state) => state.shapesMarkers,
  },
  plugins: [logrocketPlugin(LogRocket)],
});

worker.onmessage = (e) => {
  const markers = e.data;
  const shapesMarkers = {};
  Object.keys(markers)?.forEach((shape) => {
    const name = shape.endsWith('s') ? shape.slice(0, -1) : shape;
    shapesMarkers[shape] = {
      objectType: 'marker',
      markers: markers[shape],
      enabled: true,
      focused: false,
      key: name.toLowerCase(),
      label: capitalizeFirstLetter(name),
      labelLayer: undefined,
      linkedLayer: undefined,
      options: { data: undefined },
      travels: [],
      type: 'overlay',
    };
  });
  store.commit('SET_SHAPES_MARKERS', { ...shapesMarkers });
};

export default store;
