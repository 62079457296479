// Generate a random id
// https://www.w3resource.com/javascript-exercises/javascript-string-exercise-49.php
export default (len) => {
  const buf = [];
  const chars = '0123456789';
  const charlen = chars.length;
  const length = len || 32;
  for (let i = 0; i < length; i += 1) {
    buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
  }
  return buf.join('');
};
