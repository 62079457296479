<template>
  <v-app>
    <v-layout
      justify-center row fill-height
      class="fill-width"
      :align-center="!userLoggedIn || notFound || !currentApplication"
      :align-start="userLoggedIn"
    >
      <template v-if="userLoggedIn && currentApplication">
        <v-navigation-drawer
          :mini-variant="drawer"
          :mini-variant-width="56"
          :disable-resize-watcher="true"
          color="accent"
          :dark="drawerDark"
          touchless
          clipped permanent app
        >
          <SidebarMenu :menu="sideBarMenu"/>
          <v-list
            class="bottom-list"
          >
            <!-- <div>
              <-- <img src="./assets/ffly4u_desktop.png" class="hidden-sm-and-down"> ->
              <img
                src="./assets/Powered_by_ffly4u.png"
                class="hidden-md-and-up logo"
                v-show="$vuetify.breakpoint.md"
              >
            </div> -->
            <v-list-item
              v-if="canRegisterPWA"
              v-on:click="registerPWA"
              class="mx-auto"
            >
              <v-list-item-icon>
                <v-icon>mdi-open-in-app</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('misc.installPWA') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!drawer"
              class="my-2 flex-column-reverse"
              style="font-size: small;"
            >
              <span>{{ appVersion }}</span>
              <img
                src="./assets/Powered_by_ffly4u.png"
                height="52"
                class="mx-auto"
              >
            </v-list-item>
            <v-list-item
              v-else
              class="my-2 pl-1 flex-column-reverse text-caption"
              style="font-size: x-small !important; max-height: 70px;"
            >
              {{ appVersion }}
              <img
                src="./assets/Powered_by_ffly4u.png"
                height="22"
              >
            </v-list-item>
          </v-list>
          <v-divider />
          <!-- <template v-slot:append>
            <img src="./assets/Powered_by_ffly4u.png" class="hidden-sm-and-down logo">
          </template> -->
        </v-navigation-drawer>
        <v-app-bar clipped-left app>
          <v-icon
            @click="$router.go(-1)"
            color="secondary"
            class="mr-3 hidden-md-and-up"
          >
            mdi-arrow-left
          </v-icon>
          <v-toolbar-title
            v-if="customerLogo && logoLoaded"
            @click="toggleMenu"
            class="hidden-sm-and-down"
            style="cursor: pointer;"
          >
            <img
              ref="logo"
              :src="customerLogo"
              class="logo"
              @error="logoLoaded = false"
              @load="checkImgValidity()"
            >
          </v-toolbar-title>
          <v-toolbar-title
            v-else
            @click="toggleMenu"
            class="hidden-sm-and-down ml-2 pa-2"
            style="height: 64px; line-height: 47px; cursor: pointer;">
            <b class="black--text">{{ customerName }}</b>
          </v-toolbar-title>
          <v-toolbar-title
            class="hidden-sm-and-down ml-2 pa-2"
            style="height: 64px; line-height: 48px;" v-if="showApp">
            <b class="black--text">{{ vertical }}</b>
          </v-toolbar-title>
          <v-toolbar-title class="hidden-md-and-up" v-if="customerSmallLogo && smallLogoLoaded">
            <img
              refs="smallLogo"
              :src="customerSmallLogo"
              class="logo"
              @error="smallLogoLoaded = false"
              @load="checkImgValidity()"
            >
          </v-toolbar-title>
          <v-toolbar-title class="hidden-md-and-up ml-2" v-else>
            <v-avatar tile color="primary" :height="56">
              <b class="white--text">{{ customerName }}</b>
            </v-avatar>
          </v-toolbar-title>
          <v-toolbar-title class="hidden-md-and-up ml-2" v-if="showApp">
            <v-avatar tile color="primary" :height="56">
              <b class="white--text">{{ vertical }}</b>
            </v-avatar>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-layout
            align-center
            justify-center row fill-height
            class="hidden-sm-and-down"
          >
            <h3 v-if="!notFound" class="secondary--text">
              {{ pageTitle }}
            </h3>
          </v-layout>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <div class="mr-5 mt-4"
              v-if="issuesUnread"
            >
              <IssuesUnread
                :path="issuesUnread"
              />
            </div>
            <div class="mr-5 mt-4 hidden-sm-and-down">
              <DropdownTranslate style="width: 120px"/>
            </div>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  v-on="on"
                  class="pa-1"
                >
                  <v-icon
                    class="hidden-md-and-up"
                  >mdi-dots-vertical</v-icon>
                  <div
                    class="hidden-sm-and-down"
                  >
                    {{ nameUserLoggedIn }}
                  </div>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(menu, index) in menus"
                  :key="index"
                  v-on:click="actionMenu(menu)"
                  link
                  :class="menu.class"
                  :to="menu.path"
                >
                  <v-list-item-title>{{ $t(menu.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar-items>
          <v-dialog v-model="openModal" max-width="300px">
            <v-card>
              <v-card-title>{{ $t('misc.selectLanguages') }}</v-card-title>
                <RadioButtonTranslate />
              <v-divider></v-divider>
              <v-card-actions>
                <v-layout justify-end>
                  <v-btn
                    color="primary"
                    text v-on:click="openModal = false"
                  >
                    {{ $t('misc.close') }}
                  </v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-app-bar>
      </template>
      <v-main style="z-index: 1;">
        <router-view/>
      </v-main>
      <Snackbars />
      <ConsentBar v-if="userLoggedIn" />
    </v-layout>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
import Snackbars from '@/components/notifications/Snackbars.vue';
import ConsentBar from '@/components/notifications/ConsentBar.vue';
import IssuesUnread from '@/components/notifications/IssuesUnread.vue';
import SidebarMenu from '@/components/menu/SidebarMenu.vue';

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export default {
  name: 'App',
  components: {
    Snackbars,
    ConsentBar,
    IssuesUnread,
    SidebarMenu,
  },
  data() {
    const canRegisterPWA = navigator.serviceWorker
      && window.PWAregistration === 1 && !localStorage.PWA;

    const menus = [
      {
        title: 'pages.profile.titleAlt',
        path: '/profile',
      },
      {
        title: 'misc.languages',
        action: 'langues',
        class: 'hidden-md-and-up hidden-xl-and-down',
      },
      {
        title: 'auth.disconnected',
        action: 'disconnect',
      },
    ];

    // Add application selection link to menu if
    // user have more than one application
    if (this.$store.state.settings.apps && this.$store.state.settings.apps.length !== 1) {
      menus.splice(-1, 0, {
        title: 'pages.appSelector.title',
        path: '/app-selector',
      });
    }

    return {
      kuzzleDisconnected: null,
      canRegisterPWA,
      title: '',
      openModal: false,
      menus,
      checkInterval: null,
      messageRoom: null,
      drawer: this.$vuetify.breakpoint.smAndDown,
      logoLoaded: true,
      smallLogoLoaded: true,
      appVersion: `v${process.env.VUE_APP_VERSION}`,
    };
  },
  computed: {
    ...mapGetters('settings', ['get_page_title', 'GET_PAGES', 'currentApplication']),
    ...mapGetters('auth', ['userPermissions']),
    /**
     * Get state of connection to Kuzzle
     *
     * @return {boolean} Return true if Kuzzle is connected otherwise false
     */
    kuzzleConnected() {
      return this.$store.state.auth.online
        && (!this.$store.state.auth.currentUser || this.$store.state.settings.loaded);
    },
    /**
     * Get state of user login
     *
     * @return {boolean} Return true if user is logged otherwise false
     */
    userLoggedIn() {
      return !!this.$store.state.auth.currentUser;
    },
    /**
     * Get current application id
     *
     * @return {string} Return the current application id
     */
    currentApplication() {
      return localStorage.currentApplication;
    },
    /**
     * Check if more of one application are availables
     *
     * @return {boolean} Return true if more of one application are availables
     */
    showApp() {
      return this.$store.state.settings.apps.length >= 1;
    },
    /**
     * Get current application's vertical
     *
     * @return {string} Return the name of current application
     */
    vertical() {
      const applicationData = this.$store.state.settings.apps
        .find((app) => (app.id === this.currentApplication));
      const { vertical } = applicationData;
      return vertical;
    },
    /**
     * Get logo of customer
     *
     * @return {string|null} Return the logo of customer
     */
    customerLogo() {
      return this.$store.state.settings.logo;
    },
    /**
     * Get logo of customer in small version
     *
     * @return {string|null} Return the small version of logo of customer
     */
    customerSmallLogo() {
      return this.$store.state.settings.smallLogo;
    },
    /**
     * Get name of customer
     *
     * @return {string|null} Return the name of customer
     */
    customerName() {
      return this.$store.state.settings.name;
    },
    /**
     * Get name of logged user
     *
     * @return {string|null} Return the name of logged user
     */
    nameUserLoggedIn() {
      return this.$store.state.auth.currentUser.username;
    },
    /**
     * Get list of available pages for menu
     *
     * @return {string|null} Return lista of pages for menu
     */
    pages() {
      return this.$store.getters['settings/GET_PAGES'].map((page) => (page.route));
    },
    /**
     * Get list of available pages for menu
     *
     * @return {object} Return list of pages for menu
     */
    sideBarMenu() {
      const menu = {
        pages: [],
        subMenus: [],
      };

      if (!this.GET_PAGES
        || !Array.isArray(this.GET_PAGES)
        || this.GET_PAGES.length === 0
        || !this.currentApplication) {
        return {};
      }
      const pages = this.$store.getters['settings/GET_PAGES'].map((page) => (page.route));
      menu.pages = pages.filter(
        (page) => (!page.visualization && !page.configuration && !page.permissions),
      );
      const visualization = pages.filter((page) => !!page.visualization);
      const configuration = pages.filter((page) => !!page.configuration);
      const permissions = pages.filter((page) => !!page.permissions);
      const {
        expandVisualizationSection,
        expandConfigurationSection,
        expandPermissionsSection,
      } = this.$store.getters['settings/currentApplication'];
      menu.subMenus.push({
        name: 'Visualization',
        icon: 'mdi-eye-outline',
        expand: expandVisualizationSection,
        subPages: visualization,
      });
      if (this.userPermissions?.type
        && typeof this.userPermissions.type === 'string'
        && ['ADMIN', 'MANAGER'].indexOf(this.userPermissions.type.toUpperCase()) !== -1) {
        menu.subMenus.push({
          name: 'Configuration',
          icon: 'mdi-cogs',
          expand: expandConfigurationSection,
          subPages: configuration,
        });
      }
      if (permissions.length) {
        menu.subMenus.push({
          name: 'Permissions',
          icon: 'mdi-account-lock-outline',
          expand: expandPermissionsSection,
          subPages: permissions,
        });
      }
      return menu;
    },
    /**
     * Check if page is not found
     *
     * @return {boolean} Return true if current page is not found otherwise false
     */
    notFound() {
      return !this.$store.state.settings.currentPage;
    },
    /**
     * Get the path of issues page if available
     *
     * @return {string|boolean} Return path of issues page
     */
    issuesUnread() {
      const issuesPage = this.$store.getters['settings/GET_PAGES'].find(
        (page) => (page.options[0] && page.options[0].issuesUnread),
      );

      if (issuesPage) {
        return issuesPage.route.path;
      }
      return false;
    },
    /**
     * Check if drawer is to dark to read links in white
     *
     * @return {string|boolean} Return true if drawer need enabled dark theme otherwise false
     */
    drawerDark() {
      const colorAccent = this.$vuetify.theme.themes.light.accent;
      if ((((16777215 - parseInt(colorAccent.replace('#', ''), 16)) / 16777215) * 100) > 50) {
        return true;
      }
      return false;
    },
    pageTitle() {
      if (!this.title) {
        return '';
      }
      if (this.title.includes(':')) {
        return this.title;
      }
      return `${this.title}${this.get_page_title}`;
    },
  },
  watch: {
    /**
     * Update when status of connection to kuzzle change
     * Active an loader and emit message when kuzzle is not connected
     */
    kuzzleConnected() {
      if (this.kuzzleConnected) {
        document.getElementById('loader').className = 'lds-ring hide';
        setTimeout(() => {
          document.getElementById('loader').style.display = 'none';
        }, 800);

        this.removeMessageDisconnect();
      }
      // else {
      //   this.addMessageDisconnect();
      // }
    },
    /**
     * Update title of page when route change
     */
    $route() {
      setTimeout(() => {
        // TOTHINK improve this
        this.title = document.title.substr('ffly4u IoT Platform - '.length);
      }, 200);
    },
    /**
     * Update when user is logged in
     */
    userLoggedIn() {
      const params = {
        locale: {
          name: 'locale',
          type: 'keyword',
          keyword: this.$i18n.locale,
        },
      };

      this.$store.dispatch('auth/UPDATE_SELF', params);
    },
  },
  /**
   * Disable loader when kuzzle is connected
   */
  async mounted() {
    if (this.kuzzleConnected) {
      document.getElementById('loader').className = 'lds-ring hide';
      setTimeout(() => {
        document.getElementById('loader').style.display = 'none';
      }, 600);
    }
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * Register application in PWA
     */
    async registerPWA() {
      if (window.PWAregistration === 1) {
        setTimeout(this.registerPWA, 100);
      } else if (window.PWAregistration !== 0) {
        // If the environment variable is defined, we use it instead of window.publicVapidKey
        const apiKey = process.env.VUE_APP_APIKEY || window.publicVapidKey;
        const subscription = await window.PWAregistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(apiKey),
        });

        try {
          await fetch('/subscribe', {
            method: 'POST',
            body: JSON.stringify(subscription),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          localStorage.PWA = true;
        } catch (error) {
          localStorage.PWA = false;
          const data = {
            id: null,
            title: 'Error',
            description: error.message || 'An error occurred',
            color: 'error',
            timeout: 5000,
          };
          this.$store.commit('snackbars/ADD_MESSAGE', data, { root: true });
        }
      }
      this.canRegisterPWA = navigator.serviceWorker && !localStorage.PWA;
    },
    /**
     * Action for menu
     *
     * @param {Record<string, unknown>} menu Object of menu clicked
     */
    async actionMenu(menu) {
      switch (menu.action) {
        case 'disconnect':
          await this.$store.dispatch('auth/LOG_OUT');
          return this.$router.push('/login');
        case 'langues':
          this.openModal = true;
          break;
        default:
          return false;
      }
      return false;
    },
    /**
     * Add message of disconnection if message not already exist
     */
    addMessageDisconnect() {
      if (!this.kuzzleDisconnected) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Add disconnect message');
        }
        const data = {
          loading: true,
          title: this.$t('auth.disconnected'),
          description: this.$t('auth.disconnect'),
          color: 'error',
          timeout: -1,
        };
        this.$store.commit('snackbars/ADD_MESSAGE', data);
        this.kuzzleDisconnected = data.id;
      }
    },
    /**
     * Remove message of disconnection if exist
     */
    removeMessageDisconnect() {
      if (this.kuzzleDisconnected) {
        this.$store.commit('snackbars/REMOVE_MESSAGE', this.kuzzleDisconnected);
        this.kuzzleDisconnected = null;
      }
    },
    /**
     * Toggle status of menu (open/closed)
     */
    toggleMenu() {
      this.drawer = !this.drawer;
    },
    isImageOk(img) {
      return img
        && img.complete
        && typeof img.naturalWidth !== 'undefined'
        && img.naturalWidth !== 0;
    },
    checkImgValidity() {
      const { logo, smallLogo } = this.$refs;
      this.logoLoaded = this.isImageOk(logo);
      this.smallLogoLoaded = this.isImageOk(smallLogo);
    },
  },
  metaInfo() {
    let pageName = '';
    if (this.$store.state.settings.currentPage) {
      pageName = this.$store.state.settings.currentPage.route.name;
      pageName = this.$t(`pages.${pageName}.title`.toLowerCase());
      this.title = pageName;
    }
    return {
      title: '',
      titleTemplate: `ffly4u IoT Platform - ${pageName} %s`,
    };
  },
};
</script>

<style>
  /**
   * Fix strange margin with default padding. "v-main" container is rendered 12px too high
   * ? maybe it's an Vuetify error ?
   */
  .v-main {
    margin-top: 12px;
  }
  @media (min-width: 900px) {
    .container {
      max-width: calc(100% - 79px) !important;
    }
  }
  /* TODO check to improve on large HDPI screens (4K) */
  /* Remove extra margin for small screens with high resolution (HDPI) */
  @media (min-resolution: 120dpi) and (max-width: 1536px) {
    .container {
      max-width: 100% !important;
    }
    .v-application .container .layout {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .logo {
    max-height: 55px;
    margin-top: 8px;
    max-width: 200px;
    min-width: 90px;
  }
  .v-toolbar__content {
    padding-right: 0px !important;
  }
  .v-menu__content {
    margin-left: 12px;
  }
  @media screen and (min-width: 200px) and (max-width: 960px) {
    .v-menu__content {
      margin-left: 22px !important;
    }
  }
  .fill-width {
    margin-left: 0px!important;
    margin-right: 0px!important;
  }
  .bottom-list {
    position: absolute !important;
    bottom: 0px;
    padding-bottom: 0px;
    width: 100%;
  }
  .striped tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
  }
  .striped tbody tr:hover {
   background-color: rgba(0, 0, 0, .15) !important;
  }

</style>
