import Vue from 'vue';
import VueI18n from 'vue-i18n';
import DropdownTranslate from '../components/languages/DropdownTranslate.vue';
import RadioButtonTranslate from '../components/languages/RadioButtonTranslate.vue';

Vue.use(VueI18n);
Vue.component('DropdownTranslate', DropdownTranslate);
Vue.component('RadioButtonTranslate', RadioButtonTranslate);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  if (process.env.NODE_ENV !== 'production') {
    messages.dev = {};
  }
  return messages;
}

let fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en';
if (process.env.NODE_ENV !== 'production') {
  fallbackLocale = 'dev';
}

let instance = null;
export default function createI18n(locale) {
  // Check if instance of VueI18n already exist otherwise instantiate it
  if (!(instance instanceof VueI18n)) {
    instance = new VueI18n({
      locale: locale || process.env.VUE_APP_I18N_LOCALE || 'en',
      fallbackLocale,
      messages: loadLocaleMessages(),
    });
  }
  return instance;
}
