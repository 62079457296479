<template>
  <div>
    <v-list v-if="menu && menu.pages">
        <v-list-item
          v-for="(page, i) in menu.pages"
          :key="i"
          :to="page.path"
        >
          <v-list-item-icon>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="$t(`pages.${page.name}.title`.toLowerCase())"
            />
          </v-list-item-content>
        </v-list-item>
    </v-list>
    <menu-list-group
      v-for="(group, i) in menu.subMenus"
      :key="i"
      :group="group"
    />
  </div>
</template>

<script>
import MenuListGroup from './MenuListGroup.vue';

export default {
  components: { MenuListGroup },
  name: 'sidebar-menu',
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
