import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import uniqWith from 'lodash/uniqWith';
import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import * as dotWild from 'dot-wild';

const functions = {
  find,
  merge,
  mergeWith,
  uniqWith,
  unionWith,
  isEqual,
  get: dotWild.get,
  set: (data, path, value) => {
    let result = data;

    if (Array.isArray(value) && path.indexOf('*') > -1) {
      value.forEach((v, index) => {
        const subpath = path.replace('*', index);
        result = functions.set(result, subpath, v);
      });
    } else {
      result = dotWild.set(result, path, value);
    }

    return result;
  },
  debounce,
  /**
   * Deep merge for objects with merge of arrays.
   *
   * @todo Maybe add options to choose merge style (default concat)
   *
   * @param {object} object The destination object.
   * @param {object} sources The source objects.
   *
   * @return {object} Return merged object
   */
  deepMerge(object, sources) {
    const customizer = (obj, src) => {
      if (Array.isArray(obj)) {
        // Most effective method of merge.
        return [...obj, ...src];
      }
      return src;
    };
    return mergeWith(object, sources, customizer);
  },
  /**
   * Check if value(s) are empty
   *
   * @param {*} values one or multiple variable to check
   *
   * @return return the first true if found an empty value or false if none are empty
   */
  isEmpty(...values) {
    for (let i = 0; i < values.length; i += 1) {
      const value = values[i];
      // Undefined or null is empty
      if (typeof value === 'undefined' || value === null) {
        return true;
      }
      // For String and Array check if length is more than 0
      if ((typeof value === 'string' || value instanceof Array) && value.length === 0) {
        return true;
      }
      // For Map and Set check if size is more than 0
      if ((value instanceof Map || value instanceof Set) && value.size === 0) {
        return true;
      }
      // For Object check if entries length is more than 0
      if (typeof value === 'object') {
        return Object.entries(value).length === 0;
      }
      // TODO add forgotten types
    }
    return false;
  },
  /**
   * Get the boolean value of a variable
   * @param {*} value The scalar value being converted to a boolean
   *
   * @return The boolean value of variable
   */
  boolVal(value) {
    // true or string "true" is true
    if (value === true || value === 'true') {
      return true;
    }
    // false or string "false" is false
    if (value === false || value === 'false') {
      return false;
    }
    // null and undefined is false
    if (value === null || typeof value === 'undefined') {
      return false;
    }
    // Empty string or array is false
    if (Array.isArray(value) || typeof value === 'string') {
      return value.length > 0;
    }
    // Empty object is false
    if (typeof value === 'object') {
      return Object.values(value).length > 0;
    }
    const nbrVal = Number(value);
    // Number inferior to 0 is false
    if (!Number.isNaN(nbrVal) && nbrVal <= 0) {
      return false;
    }

    return true;
  },
  /**
   * Validate a data
   *
   * @param {string} type Type of validation
   * @param {*} testValue Value of caparison
   * @param {*} value Value to test
   *
   * @return {boolean} return true if data is valid otherwise false
   */
  validate(type = 'equal', testValue, value) {
    const tests = {
      above: (from, to) => (from > to),
      least: (from, to) => (from >= to),
      below: (from, to) => (from < to),
      most: (from, to) => (from <= to),
      // eslint-disable-next-line eqeqeq
      equal: (from, to) => (from == to), // Exceptionally allow == for non-strict validation
      deepEqual: (from, to) => (from === to),
      regExp: (from, to) => (new RegExp(from).test(to)),
    };

    if (Array.isArray(value)) {
      let result = true;
      for (let i = 0; i < value.length && result; i += 1) {
        result = this.validate(type, testValue, value[i]);
      }
      return result;
    }

    if (['above', 'least', 'below', 'most'].includes(type)) {
      const _testValue = parseFloat(testValue);
      const _value = parseFloat(value);
      if (!Number.isNaN(_testValue) && !Number.isNaN(_value)) {
        return tests[type](_testValue, _value);
      }
    }
    if (['equal', 'deepEqual'].includes(type)) {
      return tests[type](`${testValue}`, `${value}`);
    }
    if (type === 'regExp') {
      return tests[type](testValue, value);
    }
    return false;
  },
};

export default functions;
