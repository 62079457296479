import BaseBackend from './BaseBackend';
import AuthController from './controllers/AuthController';
import _ from '../misc/lodash';

import Api from './api';

const QUERY_PARAMS = [
  'application',
  'customer',
  'before',
  'after',
  'limit',
  'page',
  'level',
  'centroid',
  'cluster',
  'notebook',
];

class Lambda extends BaseBackend {
  /**
   * @type {Api}
   */
  connection;

  constructor({ config, store }) {
    super(store);

    let backend = {};
    const defaultConfig = {
      host: '',
      apiKey: '',
    };

    if (process.env.VUE_APP_LAMBDA_HOST) {
      backend = {
        host: process.env.VUE_APP_LAMBDA_HOST,
        apiKey: process.env.VUE_APP_LAMBDA_KEY,
      };
    } else if (typeof config !== 'undefined') {
      backend = config;
    }

    backend = _.deepMerge(defaultConfig, backend);

    if (backend.host !== '') {
      this.connection = new Api(backend);
    }

    this.useController(AuthController, 'auth');
  }

  setJwtToken(token) {
    this.jwt = token;
  }

  async connect() {
    this.store.commit('auth/SET_ONLINE');
    await this.store.dispatch('auth/CHECK_TOKEN');
  }

  // eslint-disable-next-line class-methods-use-this
  _debugQuery(config) {
    // Build query url
    const baseURL = (config.baseURL) ? `${config.baseURL}/` : '';
    const debugURL = new URL(`${baseURL}${config.url}`);
    // Add params to url
    if (config.params) {
      Object.entries(config.params).forEach(([key, value]) => {
        debugURL.searchParams.append(key, value);
      });
    }

    console.log({
      url: debugURL.href,
      method: config.method,
      headers: config.headers,
      body: config.data,
    });
  }

  /**
   * Build a query object
   *
   * @param {string} object object to request
   * @param {{id: string [key:string]: unknown }} request data of request
   * @param {Boolean} [skipAppAndCustomerParams=false] tells if we must not include
   * application and customer query params into the request
   *
   * @returns {{ path: string, params: Record<string, unknown>, body: Record<string, unknown> }}
   */
  buildQuery(object, { id, ..._params }, skipAppAndCustomerParams = false) {
    const path = [object, id].filter((p) => typeof p !== 'undefined').join('/');

    const params = {};
    const body = {};
    Object.keys(_params).forEach((key) => {
      const obj = QUERY_PARAMS.includes(key) ? params : body;
      if (typeof _params[key] !== 'undefined') {
        obj[key] = _params[key];
      }
    });

    if (!skipAppAndCustomerParams && (typeof params.application === 'undefined' || typeof params.customer === 'undefined')) {
      const { vertical, customer } = this.store.getters['settings/currentApplication'];
      params.application = vertical;
      params.customer = customer;
    }

    if (typeof params.page !== 'undefined') {
      params.page -= 1;
    }

    return {
      path,
      params,
      body,
    };
  }

  /**
   * Search items
   *
   * @param {string} object object to request
   * @param {Record<string, unknown>} _request params of request
   * @param {boolean} [all] define if need get all result (default: false)
   *
   * @returns {Record<string, unknown>} a key-value object containning request data
   */
  async search(object, _request, all = false) {
    let itemPerPage = (this.store.getters['settings/getItemsPerPage']);
    itemPerPage = itemPerPage || 15;
    const request = {
      page: 1,
      limit: itemPerPage,
      ..._request,
    };
    if (all && object === 'beacon') {
      request.limit = 100000;
    } else if (all) {
      delete request.limit;
    }
    const { path, params, body } = this.buildQuery(object, request);
    /**
     ** Define empty data object to normalize output in error case
     *! in the case of a response with a status other than "200", returns nothing
     */
    const emptyData = { currentResult: 0, totalResult: 0, body: [] };
    let data = emptyData;
    let status = 204;
    try {
      ({ data, status } = await this.connection.post(path, params, body));
      if (status !== 200) {
        data = emptyData;
      }
      if (all) {
        const restRequests = [];
        let result = data.currentResult;
        if (params.page === 0) {
          params.page = 1;
        }
        for (; result < data.totalResult; result += params.limit) {
          params.page += 1;
          restRequests.push(this.connection.post(path, params, body));
        }
        return (await Promise.all(restRequests)).reduce((acc, nextPage) => {
          // Handle empty response (status other than "200", returns nothing)
          const currentData = nextPage.status === 200 ? nextPage.data : emptyData;

          acc.currentResult += currentData.currentResult;
          acc.body = acc.body.concat(currentData.body);
          return acc;
        }, data);
      }
    } catch (error) {
      if (error.isAxiosError && process.env.NODE_ENV !== 'production') {
        this._debugQuery(error.config);
      }
      throw error;
    }
    return data;
  }

  /**
   * Search items
   *
   * @param {string} object object to request
   * @param {Record<string, unknown>} _request params of request
   * @param {boolean} [all] define if need get all result (default: false)
   *
   * @returns {Record<string, unknown>} a key-value object containning request data
   */
  async export(object, _request, all = false) {
    let itemPerPage = (this.store.getters['settings/getItemsPerPage']);
    itemPerPage = itemPerPage || 15;
    const request = {
      page: 1,
      limit: all ? 10000 : itemPerPage,
      ..._request,
    };
    const { path, params, body } = this.buildQuery(object, request);
    /**
     ** Define empty data object to normalize output in error case
     *! in the case of a response with a status other than "200", returns nothing
     */
    const emptyData = { currentResult: 0, totalResult: 0, body: [] };
    let data = emptyData;
    let status = 204;
    try {
      ({ data, status } = await this.connection.post(path, params, body, 'blob'));
      if (status !== 200) {
        data = {};
      }
    } catch (error) {
      if (error.isAxiosError && process.env.NODE_ENV !== 'production') {
        this._debugQuery(error.config);
      }
      throw error;
    }
    return data;
  }

  /**
   * Create item
   *
   * @param {string} object object to request
   * @param {Record<string, unknown>} body data of update
   * @param {Record<string, unknown>} _params params of request
   *
   * @return {Record<string, unknown>|false}
   */
  async create(object, body, _params) {
    const { path, params } = this.buildQuery(object, { id: 'create', ..._params });
    try {
      const { data, status } = await this.connection.post(path, params, body);
      if (status === 200) {
        return data;
      }
    } catch (error) {
      if (error.isAxiosError && process.env.NODE_ENV !== 'production') {
        this._debugQuery(error.config);
      }
      throw error;
    }
    return false;
  }

  /**
   * Read item
   *
   * @param {string} object object to request
   * @param {string} id id of item
   * @param {Record<string, unknown>} _params params of request
   *
   * @return {Record<string, unknown>}
   */
  async read(object, id, _params, skipAppAndCustomerParams = false) {
    const { path, params } = this.buildQuery(object, { id, ..._params }, skipAppAndCustomerParams);
    try {
      const { data, status } = await this.connection.get(path, params);
      if (status === 200) {
        return data;
      }
    } catch (error) {
      if (error.isAxiosError && process.env.NODE_ENV !== 'production') {
        this._debugQuery(error.config);
      }
      throw error;
    }
    return {};
  }

  /**
   * Get the list of user applications
   *
   * @return {Record<string, unknown>}
   */
  async fetchApplications() {
    const path = 'authent';
    try {
      const { data, status } = await this.connection.get(path, {});
      if (status === 200) {
        return data;
      }
    } catch (error) {
      if (error.isAxiosError && process.env.NODE_ENV !== 'production') {
        this._debugQuery(error.config);
      }
      throw error;
    }
    return {};
  }

  /**
   * Update item
   *
   * @param {string} object object to request
   * @param {string} id id of item
   * @param {Record<string, unknown>} body data of update
   * @param {Record<string, unknown>} _params params of request
   *
   * @return {Record<string, unknown>|false}
   */
  async update(object, id, body, _params) {
    const { path, params } = this.buildQuery(object, { id, ..._params });
    try {
      const { data, status } = await this.connection.put(path, params, body);
      if (status === 200) {
        return data;
      }
    } catch (error) {
      if (error.isAxiosError && process.env.NODE_ENV !== 'production') {
        this._debugQuery(error.config);
      }
      throw error;
    }
    return false;
  }

  /**
   * Delete item
   *
   * @param {string} object object to request
   * @param {string} id id of item
   * @param {Record<string, unknown>} body data of update
   * @param {Record<string, unknown>} _params params of request
   *
   * @return {Record<string, unknown>|false}
   */
  async delete(object, id, _params) {
    const { path, params } = this.buildQuery(object, { id, ..._params });
    try {
      const { data, status } = await this.connection.delete(path, params);
      if (status === 200) {
        return data;
      }
    } catch (error) {
      if (error.isAxiosError && process.env.NODE_ENV !== 'production') {
        this._debugQuery(error.config);
      }
      throw error;
    }
    return false;
  }
}

export default Lambda;
