<template>
  <v-layout align-center justify-start fill-height>
    <div class="ml-3">
      <v-flex
        style="min-width:120px"
        xs4 sm3 md3>
        <v-radio-group
          v-model="$i18n.locale">
          <v-radio
            v-for="lang in langs"
            :key="lang"
            :label="getLabel(lang)"
            :value="lang.value"
          ></v-radio>
        </v-radio-group>
      </v-flex>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'RadioButtonTranslate',
  data() {
    return {
      langs: this.getLanguages(),
    };
  },
  methods: {
    getLanguages() {
      return Object.keys(this.$i18n.messages).map((lang) => (
        {
          value: lang,
        }
      ));
    },
    getLabel(item) {
      return this.$t(`languages.${item.value}`);
    },
  },
  watch: {
    '$store.state.settings.locales': function w() {
      this.langs = this.getLanguages();
    },
  },
};
</script>
