import Vue from 'vue';
import Router from 'vue-router';
import getView from './views';
import guardGenerator from './guards';
// import Backend from '../backend';

Vue.use(Router);

const createRouter = (store) => {
  const getGuards = guardGenerator(store);

  const routes = [
    {
      path: '/profile',
      beforeEnter: getGuards(['authentication']),
      name: 'profile',
      component: getView('./generic/Profile.vue'),
    },
    {
      path: '/login',
      beforeEnter: getGuards(['connect']),
      name: 'login',
      component: getView('./common/Login.vue'),
    },
    {
      path: '/invite',
      beforeEnter: getGuards(['connect']),
      name: 'invite',
      component: getView('./common/Register.vue'),
    },
    {
      path: '/reset',
      beforeEnter: getGuards(['connect']),
      name: 'reset',
      component: getView('./common/Reset.vue'),
    },
    {
      path: '/reset-password',
      beforeEnter: getGuards(['connect']),
      name: 'reset-password',
      component: getView('./common/ResetPassword.vue'),
    },
    {
      path: '/new-password',
      beforeEnter: getGuards(['connect']),
      name: 'new-password',
      component: getView('./common/NewPassword.vue'),
    },
    {
      path: '/valid',
      beforeEnter: getGuards([]),
      // we do not put a guard here to allow connected users to valid their email address
      name: 'valid-email',
      component: getView('./common/ValidEmail.vue'),
    },
    {
      path: '/email-sent',
      beforeEnter: getGuards(['connect']),
      name: 'email-sent',
      component: getView('./common/EmailSent.vue'),
    },
    {
      path: '/app-selector',
      beforeEnter: getGuards(['authentication']),
      name: 'app-selector',
      component: getView('./common/AppSelector.vue'),
    },
  ];

  if (process.env.NODE_ENV !== 'production') {
    routes.push({
      path: '/fieldsTest',
      name: 'fieldsTest',
      component: getView('./dev/FieldsTest.vue'),
    });
    routes.push({
      path: '/tableTest',
      name: 'tableTest',
      component: getView('./dev/TableTest.vue'),
    });
    routes.push({
      path: '/filtersTest',
      name: 'filtersTest',
      component: getView('./dev/FiltersTest.vue'),
    });
  }

  store.state.settings.pages.forEach((page) => {
    const route = {
      beforeEnter: getGuards(page.route.guards || []),
      path: page.route.path,
      name: page.route.name,
      component: getView(page.route.component),
      props: page,
    };

    routes.push(route);
  });

  routes.push(...[
    {
      path: '*',
      beforeEnter: getGuards(['authentication']),
      // name: 'notfound',
      component: getView(),
    },
  ]);

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach((to, _from, next) => {
    // Check if user is connected
    if (store.state.auth.currentUser !== null) {
      // If currentApplication is not set and fullPath not /app-selector
      if (!localStorage.currentApplication && to.fullPath !== '/app-selector') {
        localStorage.nextPath = to.fullPath;
        localStorage.redirect = true;
        next('/app-selector');
        // If it's redirect and fullPath not /app-selector
      } else if (localStorage.redirect === 'true' && to.fullPath !== '/app-selector') {
        localStorage.redirect = false;
        next(localStorage.nextPath);
      }
    }
    next();
  });

  router.afterEach((to) => {
    store.commit('settings/SET_CURRENT_PAGE', to);
  });

  return router;
};

export default createRouter;
