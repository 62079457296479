import axios from 'axios';
import { Storage } from 'aws-amplify';
import _ from '@/misc/lodash';
import ConfStorage from '@/misc/Storage';
import format from '@/misc/dataAccess/formats/dateTime';
import Backend from '../backend';

async function fetchLogo(app, logo) {
  try {
    app[logo] = await Storage.get(app[logo], { identityId: 'app_logo' });
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('error when fetching logo from s3');
    }
  }
}

const state = {
  name: '', // customer's name
  id: null,
  fakeData: false,
  logo: null,
  smallLogo: null,
  stripedTables: false,
  theme: {
    primary: '#00538b',
    // primary: '#ffcd00',
    // primary: '#bbb',
    secondary: '#484d52',
    accent: '#00538b',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
  locales: {
    /*
    en: {
      languages: {
        en: 'ENGLISH LANG',
        fr: 'FRENCH LANG',
      },
    },
   */
  },
  applications: [],
  pages: [
    /* {
      application: '',
      name: 'Account',
      path: '/profile',
      component: '',
      guards: [],
      home: false,
      icon: 'mdi-account',
      settings: {},
    }, */
  ],
  currentApplication: ConfStorage.get('currentApplication'),
  // The list of user applications, fetched from backend
  apps: null,
  currentPage: null,
  loaded: false,
  pagination: ConfStorage.getObject('pagination'),
  itemsPerPage: ConfStorage.getObject('itemsPerPage'),
  // The last part of the page title in general object id, or friendly name
  pageTitle: '',
  vertical: '', // mySkip, myRTP, myDrum, myAsset
  isConfigValid: true,
};

const actions = {
  FETCH_APPLICATIONS: async ({ commit, dispatch }) => {
    try {
      let apps = await Backend().fetchApplications();
      apps = apps.map((app) => ({ ...app }));
      commit('SET_APPS', apps);
      if (apps.length === 1) {
        const [{ customer, id }] = apps;
        await dispatch('FETCH_SETTINGS', { customer, id });
      }
      state.loaded = true;
    } catch (error) {
      commit('SET_APPS', []);
      let description = error.message || 'An error occurred';
      state.loaded = true;
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      if (error.request) {
        // The request was made but no response was received
        // console.log(error.request);
        try {
          const realTime = await axios.get('http://worldclockapi.com/api/json/utc/now');
          const time = new Date();
          const currentTime = new Date(realTime.data.currentDateTime);
          // const availableLocale = { fr, en };
          if (Math.abs(currentTime.getTime() - time.getTime()) > 60000) {
            const desc = {
              en: `Please set the proper date for you device (your phone time,
              or your computer time) then refresh the page.\n<br>
              Current time is ${await format(currentTime.getTime(), {})}`,
              fr: `Veuillez régler la date et l'heure exactes de votre appareil
              (heure de votre téléphone ou de votre ordinateur) et rafraîchir la page.
              L 'heure actuelle est ${await format(currentTime.getTime(), {})}`,
            };
            description = desc[localStorage.locale];
          }
        } catch (_error) {
          //
        }
      }

      const data = {
        id: null,
        title: 'Error',
        description,
        color: 'error',
        timeout: -1,
      };
      commit('snackbars/ADD_MESSAGE', data, { root: true });
    }
  },
  FETCH_SETTINGS: async ({ state, commit, dispatch }, params) => {
    const useLocalConfFile = process.env.VUE_APP_LOCAL_CONF_FILE;
    try {
      const currentAppData = state.apps.find((app) => app.id === params.id);
      let applicationsData;
      let name;
      let vertical;
      if (useLocalConfFile) {
        applicationsData = await Backend().read('applicationSettings', 'test-user', {}, true);
        ({ name, vertical } = applicationsData);
        applicationsData = {
          ...applicationsData,
          id: currentAppData.id,
          name: name || currentAppData.customer,
          applications: [
            {
              ...applicationsData.applications[0],
              ...currentAppData,
            },
          ],
          vertical: vertical || currentAppData.vertical,
        };
      } else {
        applicationsData = await Backend().read('config', undefined, {
          customer: params.customer,
          application: currentAppData.vertical,
        });
        ({ name, vertical } = applicationsData);
        applicationsData = {
          id: currentAppData.id,
          name: name || currentAppData.customer,
          applications: [{
            ...applicationsData,
            ...currentAppData,
          }],
          vertical: vertical || currentAppData.vertical,
        };
      }

      if (applicationsData.applications[0].logo) {
        await fetchLogo(applicationsData.applications[0], 'logo');
      }

      if (applicationsData.applications[0].smallLogo) {
        await fetchLogo(applicationsData.applications[0], 'smallLogo');
      }
      commit('SET_SETTINGS', applicationsData);
      await dispatch('SET_CURRENT_APPLICATION', currentAppData.id);
      commit('SET_CONFIG_FILE_STATE', true);
      await dispatch('auth/FETCH_PERMISSIONS', {}, { root: true });
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        // console.error(error);
        console.log('Error when fetching config');
      }
      commit('CLEAR_CURRENT_APP_OBJECT');
      commit('SET_CONFIG_FILE_STATE', false);
      // throw error;
    }
  },
  SET_CURRENT_APPLICATION: ({ state, commit, dispatch }, applicationId) => {
    let fakeData = false;
    const applicationData = state.applications.find((app) => (app.id === applicationId));
    state.appId = applicationId;
    if (applicationData) {
      // Use data from fakeData page to load fakeCrud and remove it from the pages
      if (applicationData.pages && Array.isArray(applicationData.pages)) {
        const fakeDataIndex = applicationData.pages.findIndex((page) => page.fakeData === true);
        if (fakeDataIndex !== -1) {
          ([fakeData] = applicationData.pages.splice(fakeDataIndex, 1));
        }
      }
    }
    dispatch('LOAD_CRUD', fakeData, { root: true });
    commit('SET_CURRENT_APPLICATION', applicationData);
  },
  CLEAR_SELECTED_APPLICATION({ commit }) {
    commit('CLEAR_CURRENT_APP_OBJECT');
  },
};

const mutations = {
  SET_SETTINGS: (state, data) => {
    Object.assign(state, data);

    state.loaded = true;
  },
  SET_CURRENT_APPLICATION: (state, applicationData) => {
    if (applicationData) {
      for (let i = 0; i < applicationData.pages.length; i += 1) {
        const page = applicationData.pages[i];
        page.application = applicationData.id;
        state.pages.push(page);
      }

      if (!_.isEmpty(applicationData.locales)) {
        Object.entries(applicationData.locales).forEach(([locale, data]) => {
          state.locales[locale] = _.merge(state.locales[locale] ?? {}, data);
        });
      }

      if (applicationData.theme) {
        state.theme = { ...state.theme, ...applicationData.theme };
      }

      if (applicationData.logo) {
        state.logo = applicationData.logo;
      }

      if (applicationData.smallLogo) {
        state.smallLogo = applicationData.smallLogo;
      }
      if (applicationData.stripedTables) {
        state.stripedTables = applicationData.stripedTables;
      }

      state.currentApplication = applicationData.id;
      localStorage.currentApplication = applicationData.id;
    } else {
      delete localStorage.currentApplication;
      state.currentApplication = undefined;
    }
  },
  SET_CURRENT_PAGE: (state, route) => {
    if (route.matched.length > 0) {
      state.currentPage = route.matched[0].props.default;
    } else {
      state.currentPage = null;
    }
  },
  SET_CURRENT_TITLE: (state, title) => {
    state.pageTitle = title;
  },
  SET_APPS: (state, apps) => {
    state.apps = apps;
  },
  SET_ITEMS_PER_PAGE: (state, number) => {
    state.itemsPerPage = number;
    ConfStorage.setObject('itemsPerPage', number);
  },
  SET_CONFIG_FILE_STATE: (state, value) => {
    state.isConfigValid = value;
  },
  CLEAR_CURRENT_APP_OBJECT: (state) => {
    state.currentApplication = null;
    ConfStorage.remove('currentApplication');
  },
};

const getters = {
  GET_PAGES: (state) => state.pages
    .filter((page) => page.route.menu
      && (!page.application || page.application === state.currentApplication)),
  currentApplication: (state) => (state.applications
    .find(({ id }) => id === state.currentApplication)),
  get_page_title: (state) => state.pageTitle,
  getItemsPerPage: (state) => state.itemsPerPage,
  get_apps: (state) => state.apps,
  isConfigValid: (state) => state.isConfigValid,
  stripedTables: (state) => state.stripedTables,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
