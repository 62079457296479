const storage = localStorage;
const tempStorage = sessionStorage;
function set(key, value) {
  return storage.setItem(key, value);
}

function get(key, sessionStorage = false) {
  return sessionStorage ? tempStorage.getItem(key) : storage.getItem(key);
}

function remove(key, sessionStorage = false) {
  return sessionStorage ? tempStorage.removeItem(key) : storage.removeItem(key);
}

function getObject(key, sessionStorage = false) {
  const obj = get(key, sessionStorage);
  return (obj !== 'undefined') ? JSON.parse(obj) : null;
}

function setObject(key, value, sessionStorage = false) {
  const v = JSON.stringify(value);
  return sessionStorage ? tempStorage.setItem(key, v) : storage.setItem(key, v);
}

export default {
  set,
  get,
  remove,
  getObject,
  setObject,
};
