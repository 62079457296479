<template>
  <router-link
    :to="path"
  >
    <v-badge
      overlap
    >
      <template v-slot:badge>
        <span v-if="count > 0">{{ count }}</span>
      </template>
      <v-icon>
        mdi-bell
      </v-icon>
    </v-badge>
  </router-link>
</template>

<script>
import Backend from '../../backend';

export default {
  name: 'IssuesUnread',
  props: {
    path: {
      type: String,
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    async countUnRead() {
      const applicationData = this.$store.state.settings.applications
        .find((app) => (app.id === this.$store.state.settings.currentApplication));

      const result = await this.$store.dispatch('crud/SEARCH', {
        object: 'issues',
        plugin: 'alarm-manager',
        body: {
          p: 1,
          n: 1,
          q: {
            query: {
              bool: {
                minimum_should_match: 1,
                should: [
                  {
                    nested: {
                      path: 'metadata',
                      score_mode: 'avg',
                      query: {
                        bool: {
                          must: [
                            {
                              term: {
                                'metadata.name': 'user-read',
                              },
                            },
                          ],
                          must_not: [
                            {
                              term: {
                                'metadata.keyword': this.$store.state.auth.currentUserffly4u.id,
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                  {
                    bool: {
                      must_not: [
                        {
                          nested: {
                            path: 'metadata',
                            score_mode: 'avg',
                            query: {
                              bool: {
                                must: [
                                  {
                                    term: {
                                      'metadata.name': 'user-read',
                                    },
                                  },
                                ],
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
                must: [
                  {
                    term: {
                      customer: applicationData.customer,
                    },
                  },
                  {
                    term: {
                      application: applicationData.id,
                    },
                  },
                ],
              },
            },
          },
        },
        store: false,
      });

      this.count = result.total;
    },
  },
  async mounted() {
    await this.countUnRead();

    await Backend().realtime.subscribe(
      'issues',
      'docs',
      {
        bool: {
          should: [
            {
              and: [
                {
                  equals: {
                    'metadata.name': 'user-read',
                  },
                },
                {
                  not: {
                    exists: `metadata.keyword["${this.$store.state.auth.currentUserffly4u.id}"]`,
                  },
                },
              ],
            },
            {
              not: {
                equals: {
                  'metadata.name': 'user-read',
                },
              },
            },
          ],
        },
      },
      async () => {
        await this.countUnRead();
      },
    );
  },
};
</script>
